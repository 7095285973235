import React from 'react';
import ExternalResource from '../../../components/ExternalLink';
let unit = (props) => {
    return <div>
        <p>There are many goals that society asks you to achieve, but is there something larger than focusing on one goal after the other? &nbsp;In the video below we are met with two distinct approaches to life and how you can find enjoyment when you focus on the value of any moment or interaction.</p>
        <p>While going through the video look out for the following:</p>
        <ul>
            <li>The second kid was only focused on goals, why did this negatively impact his experience of the trip? Both kids were on the same trip, but one was able to have great experience while the other did not, why is that?</li>
            <li>Why is the importance given to the value of experiencing something, in this case the journey?</li>
            <li>The second kid has the same goals as the first kid but how do curiosity, interest and appreciation for the journey impact him?</li>
            <li>What is the importance of understanding all the points at which value is being created as you navigate life and work?</li>
        </ul>
        <div className='highlighted-patch'>
            <div className='f15_b'>Links</div>
            <ExternalResource url='https://www.youtube.com/watch?v=eiPxLpYlw4I' />
        </div>
    </div>
}

export default unit;