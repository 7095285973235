import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
    return <div>
        <p>The video below showcases how even the smallest interaction can be made memorable if you pay attention to the tacit needs of a customer. John Bocuzzi Jr. narrates an interaction he had with a salesperson while he was buying a pair of glasses. What was interesting about this interaction was that while John wanted something functional, the salesperson offered him a product that actually worked better for him.</p>
        <p>While going through the video below, think of the following:</p>
        <ul>
            <li>The fact that the salesperson had managed to sell John the best product for him, most suited to his needs even when he did not articulate these needs to her.</li>
            <li>It was the experience that John had, not the affordability of the product that made John keep returning to the shop. The quality of the interaction is what made sure he was a loyal customer.</li>
        </ul>
        <div className='highlighted-patch'>
            <div className='f15_b'>Links</div>
            <ExternalResource url='https://www.youtube.com/watch?v=GH1TXfQSwUQ' />
        </div>
    </div>
}

export default unit;