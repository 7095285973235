import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>The whole purpose of Enlightened Self Interest starts with one person choosing to do things differently. In the video below you will learn more about how to move away from unenlightened self interest to enlightened self interest.</p>
    <p>One key point to think about here is to break the cycle of unenlightened self interest and nurture the spirit of enlightened self interest.</p>
   

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://www.youtube.com/watch?v=DmSMqnn6gRA" />
    </div>
  </div>
}

export default unit;