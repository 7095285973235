import React from 'react';
import ExternalResource from '../../../components/ExternalLink';
let unit = (props) => {
    return <div>
        <p>How do you ensure that you are constantly delivering your very best in everything you do? How can you be completely conscious, immersed in what you do, and be in the optimal space to ensure you deliver success, go through the materials below to know how to do so. </p>
        <p>In addition to the tips on getting into the flow at work, watch the video by violinist Diane Allen on discovering her own strategy of deeply engaging with her music. To her, music is not just about hitting the right notes, but more about the act of bringing people together to share the musical experience. Learn more about how you can find meaning and engagement with your work by following her story.</p>
        <div className='highlighted-patch'>
            <div className='f15_b'>Links</div>
            <ExternalResource url='https://www.oxford-review.com/blog-how-to-get-into-flow-at-work/' />
            <ExternalResource url='https://www.youtube.com/watch?v=ookkfdGMHeM' />
        </div>
    </div>
}

export default unit;