import React from 'react';
import ExternalResource from '../../../components/ExternalLink';
let unit = (props) => {
    return <div>
        <p>Learn more about the method of thinking that has helped many innovators achieve great success in their lifetime.</p>
        <p>A lot of times while finding solutions to problems we tend to go down the &ldquo;tried and tested,&rdquo; route. Solution thinking on the other hand, asks you to go beyond tried and tested methods and to not follow what others have done. First Principles Thinking asks you to look at the fundamental elements of the challenge you are facing and then think of solutions based on that. By doing this you will not only view the challenges you face differently but also make impactful innovations.</p>
        <p>To know more about First Principles Thinking read the following article.</p>
        <div className='highlighted-patch'>
            <div className='f15_b'>Links</div>
            <ExternalResource url='https://medium.com/seamfix-engineering/first-principles-thinking-the-foundation-of-innovation-5858e002c01b' />
        </div>
    </div>
}

export default unit;