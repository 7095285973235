import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>A business eventually serves its customers and you cannot serve your customer base if you do not know them well. At a time when racism and segregation was rampant in the United States of America, IBM President Thomas Watson Jr publicly proclaimed that in his company people would be hired on the basis of their qualifications for the job and not their race or creed.</p>
    <p>While reading the article below think of the following:</p>
    <p>Apart from creating a positive legacy for IBM as a just and honourable corporation, this move also helped the company’s profits too. Why would a diverse workforce help the company?</p>
    <p>The far- reaching impact this action had both on the company and the laws in the states it operated in.</p>
    
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://www.ibm.com/ibm/history/ibm100/us/en/icons/equalworkforce/#:~:text=Stating%20his%20position%20in%20a,race%2C%20color%20or%20creed.%E2%80%9D' />
    </div>
  </div>
}

export default unit;