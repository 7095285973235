import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>Vaibhav Sisinty scored a 30% in his tenth standard pre-board examinations. He decided to work hard and finally scored 77% in the board exams. After this, he made it to an engineering college and eventually built a site on Android – that managed to lead to building a start up at the age of 19, which eventually had to be shut down. He spent more time learning and coming up with new business ideas and learning from what those experiences taught him. Eventually through While in college he was running a business that earned him crores. Despite having a successful business, he decided to get a job with Uber, because he wanted to challenge himself.</p>
    <p>While going through his story, think of the following:</p>
    <ul>
      <li>What made Vaibhav constantly challenge himself?</li>
      <li>Why does he ask people to not get constrained by marks or degrees and instead choose to do things that they love?</li>
    </ul>
    
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://www.youtube.com/watch?v=K81N9Gpll9k" />
    </div>
  </div>
}

export default unit;