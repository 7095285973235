import React from 'react';
import ExternalResource from '../../../components/ExternalLink';
let unit = (props) => {
    return <div>
        <p>The article below begins with a bold claim, that the performance review as a process is dead. Across companies, it is the value that you add to the company that is being measured. So, while a team may be toiling away, working hard on a daily basis, they need to also understand how they are creating value for each other and for the organization.</p>
        <p>While going through the article, keep in mind the following:</p>
        <ul>
            <li>The fact that performance is not about simply doing a task, or checking a box, but actually creating value in any task that you do.</li>
            <li>The focus placed on future potential, as the world of work drastically shift, your role at the work-place is about how you will add value in an uncertain world.</li>
        </ul>
        <div className='highlighted-patch'>
            <div className='f15_b'>Links</div>
            <ExternalResource url='https://www.forbes.com/sites/cywakeman/2015/02/24/the-new-value-equation-does-your-team-really-measure-up/?sh=ef658f24281a' />
        </div>
    </div>
}

export default unit;