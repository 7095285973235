import React, { useState } from 'react';
import {
  Input,
  InputLabel,
  FormControl,
  Button,
} from "@material-ui/core";

function FirstTimeUserMsg({ user }) {
  const [accessCode, setAccessCode] = useState(null);
  const [errMsg, setErrMsg] = useState(null);
  // let handleChange = prop => event => {
  //   setAccessCode(event.target.value);
  // };
  return <div className='blk-cntr'
    style={{
      maxWidth: 450, margin: 'auto', minHeight: 'calc(100vh - 256px)',
      display: 'flex', alignItems: 'center'
    }}>
    <div>
      <div style={{ font: 'normal normal normal 14px/22px Merriweather Sans' }}>
        <div>Welcome!</div>
        <div>Your registration was successful.</div>
        <p>Please input the ACCESS CODE provided to you so we create your exploration list</p>
      </div>
      <form>
        <FormControl
          fullWidth
          className="input-cntr"
        >
          <InputLabel htmlFor="access-code">Access Code</InputLabel>
          <Input
            id="access-code"
            label="Access Code"
            type="text"
            autoComplete="off"
            value={accessCode ? accessCode : ''}
            onChange={ev => { setAccessCode(ev.target.value) }}
          />
        </FormControl>
        <FormControl
          fullWidth
          className="input-cntr"
        >
          <Button
            disabled={!accessCode}
            classes={{ root: "action-btn", label: "btn-text" }}
            onClick={() => {
              fetch(`https://us-central1-coaching-platform-illumine.cloudfunctions.net/serverResponse/access-code`, {
                method: 'POST',
                body: JSON.stringify({ accessCode, uid: user.uid })
              }).catch(err => {
                console.log(err)
              }).then(response => {
                console.log(response);
                response
                  .json()
                  .then(response => { if (!response.success) { setErrMsg(response.errMsg) } });
              });
            }}
          >
            <span>Unlock Sessions</span>
          </Button>
        </FormControl>
        <div style={{ fontSize: '0.75rem', color: '#F00', textAlign: 'center' }}>{errMsg}</div>
      </form>
    </div>
  </div>
}

export default FirstTimeUserMsg;