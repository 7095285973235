import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>In the videos below, you will hear from two different people with two disparate background talking about the importance of having a growth identity, and ways in which you ensure that you are constantly trying new things.</p>
    <p>While going through the videos below think of the following:</p>
    <ul>
      <li>The importance of setting small sustainable challenges for yourself, according to Matt Cutts to ensure you keep new trying new things and keep building your own confidence step by step.  </li>
      <li>Lucie Fink says, “I had not idea what stop motion videos were, but I wanted to try it,” what new opportunities did this I Can approach open up for her? </li>
    </ul>
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://www.youtube.com/watch?v=UNP03fDSj1U" />
      <ExternalResource url="https://www.youtube.com/watch?v=nE19w-FNm6w&feature=emb_logo" />
      {/* <ExternalResource url="https://www.hays.com.au/career-advice/upskilling/7-ways-to-upskill" title='7 ways to upskill l Hays' icon='https://digital-resources.s3.ap-south-1.amazonaws.com/Upskilling_Ways-to-upskill-main-820x320.jpg' /> */}
    </div>
  </div>
}

export default unit;