import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>In this honest account of her own life, Surbhi Gupta talks about how she stopped listening to negative comments and followed her gut.</p>
    <p>While reading through her story, try to see how she started reacting to her own thoughts and the judgments of others around her. She may have failed an exam, but she did not let that failure become a defining moment of her life, instead she worked towards finding her own passion and then following it. It is her “I Can” approach that eventually helped her find a new path for herself.</p>
    
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://yourstory.com/mystory/journey-purpose-kalakar-gift-studio?utm_pageloadtype=scroll" />
    </div>
  </div>
}

export default unit;