import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>In the material below you will learn more about how you can upskill while you study and what are the methods you can use to ensure you are ready for the world of work.  Right from understanding  people to understanding data and even consciously mapping your career, below you will find many ways in which you can ensure you are future ready. </p>
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://www.youtube.com/watch?v=C3bXnKzpzyo" />
      <ExternalResource url="https://www.hays.com.au/career-advice/upskilling/7-ways-to-upskill" title='7 ways to upskill l Hays' icon='https://digital-resources.s3.ap-south-1.amazonaws.com/Upskilling_Ways-to-upskill-main-820x320.jpg' />
      <ExternalResource url="https://www.youtube.com/watch?v=1aYfkgzWoAQ" />
    </div>
  </div>
}

export default unit;