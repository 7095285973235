import "core-js/stable";
import "regenerator-runtime/runtime";
import React, { Component } from "react";
import Main from "./Main";
import { Provider } from "react-redux";
import { persistStore, persistCombineReducers } from "redux-persist";
import { PersistGate } from "redux-persist/es/integration/react";
import { BrowserRouter as Router } from "react-router-dom";
import store from "./Redux_Store.js";
import ScrollToTop from './ScrollToTop';
import "./App.css";

class App extends Component {
  render() {
    let persistor = persistStore(store);
    return (
      <Provider store={store}>
        <PersistGate
          loading={
            <div>
              <p>Loading</p>
            </div>
          }
          onBeforeLift={() => { }}
          persistor={persistor}
        >
          <Router>
            <ScrollToTop />
              <Main />
            {/* </ScrollToTop> */}
          </Router>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
