import React from 'react';
import ExternalResource from '../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>Solution thinking is not complicated, just about anyone can do it. Below, you will find examples of ordinary citizens thinking creatively to solve the most common problems around them.</p>
    <p>Solution thinking urges people to find innovative answers to some of the toughest challenges facing our world. Right from research to execution, a solution-oriented mindset is looking to break old models. In the stories below, you can see how solution thinking can bring about new ideas to positively impact your community, society and even the environment.</p>
    <div className='f15_b'>While going through the articles below, pay special attention to the following:</div>
    {/* <p>In the articles below, you will learn more about the way solution thinking helped Amul become the beloved brand it is today, while reading the article keep in mind the following:</p> */}
    <ul>
      <li>For Sergeant Simphiwe Mgwetana, it is compassion towards the youth in his community that drives him to look beyond incarceration or punishment to keep them from committing crimes. He has managed to use solution thinking to meet his goals of reducing crime and ensuring that the youngsters become better citizens who build the community around them. </li>
      <li>Like many of us, Ashutoh Giri could have just cringed at the unsanitary conditions of public toilets in India, instead he decided to tackle the problem head on and use technology to combat sanitation. He created a business opportunity as a solution to an age-old problem.</li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://allafrica.com/stories/202009160976.html " />

      <ExternalResource url="https://yourstory.com/socialstory/2020/01/startup-public-sanitation-hygiene-iot-toilet" />
    </div>
  </div>
}

export default unit;