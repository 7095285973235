/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router";

import Registration from "../../components/RegistrationForm";
import { registerAttempt, setErrMsg, resetLoginPage } from "./redux_actions";
import { Grid } from "@material-ui/core";

let Register = class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      showPassword: false,
      firstname: "",
      lastname: "",
      name: "",
      isDesktop: false,
    };

    var that = this;
    this.checkDesktop = (e) => {
      const width = e.currentTarget && e.currentTarget.innerWidth;
      if (width <= 375) {
        that.setState({ isDesktop: false });
      } else {
        that.setState({ isDesktop: true });
      }
    };

    window.onresize = this.checkDesktop;
    window.onload = this.checkDesktop;

    this.notLoggedIn = (classes) => {
      return (
        <div className="auth-cntr">
          <Grid container style={{ height: "100%" }}>
            <Grid item lg={7} md={7} sm={12} xs={12} style={{ height: "100%" }}>
              <div
                className="login-artifact"
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  style={{ width: "100%" }}
                  src={
                    this.state.isDesktop
                      ? `/images/DRC-home-desktop.svg`
                      : `/images/DRC-home-mob.svg`
                  }
                  alt="hero-image"
                />
              </div>
            </Grid>
            <Grid item lg={5} md={5} sm={12} xs={12} style={{ height: "100%" }}>
              <div style={{ height: "100%", display: "flex" }}>
                <Registration
                  registerAttempt={this.props.registerAttempt}
                  authMsg={this.props.auth.loginErrorMsg}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      );
    };

    this.loggedIn = (
      <Redirect
        to={{
          pathname: "/app",
        }}
      />
    );
  }

  componentDidMount() {
    //reset all the values in the state;
    this.props.resetLoginPage();

    this.checkDesktop(window);
  }

  render() {
    //this.props.requestPermissionToNotify();
    const { classes } = this.props;

    if (
      this.props.location.pathname === "/channel" &&
      this.props.auth.isLoggedIn
    ) {
      return;
    } else if (this.props.auth.isLoggedIn) {
      return this.loggedIn;
    } else {
      return this.notLoggedIn(classes);
    }
  }
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      registerAttempt,
      setErrMsg,
      //requestPermissionToNotify,
      resetLoginPage,
    },
    dispatch
  );
}

export default connect(mapStateToProps, matchDispatchToProps)(Register);
