import React from 'react';
import ExternalResource from '../../../components/ExternalLink';
let unit = (props) => {
    return <div>
        <p>Buying a car is an aspiration for multitudes of Indians, but as one of the polluted countries in the world, more cars are only going to damage our environment. While Electric Vehicles are seeing buyers in the west, in India they are still not in demand because they are costly. Nikita Nishita Baliarsingh from Odisha decided to solve this problem, their answer was to build car batteries from crop residue that were environment friendly! </p>
        <p>Read more about how Nikita and Nishita were able to think outside of the box and find a new solution to an old problem.</p>
        <p>While reading the article think of the following:</p>
        <p>So what makes Elon Musk think differently? Learn more about Elon Musk’s approach to problems and challenges in the video below. While watching the video, pay special attention to how he applies first principles thinking to ideation. </p>
        <ul>
            <li>They did not let low demand for electric vehicles come in the way of their dreams to build eco friendly electric vehicles. </li>
            <li>They investigated the problem thoroughly, in doing so they turned a challenge into an opportunity. </li>
        </ul>
        <div className='highlighted-patch'>
            <div className='f15_b'>Links</div>
            <ExternalResource url='https://www.thebetterindia.com/251529/electric-vehicles-ev-lithium-batteries-crop-residue-nikita-nishita-baliarsingh-odisha-startup-nexus-powers-eco-friendly-sustainable/' />
        </div>
    </div>
}

export default unit;