import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>Sudhanshu Vats, today is a well-known CEO in India, he has lead companies like Viacom 18 and is currently the CEO & MD of EPL Limited. However, when he had started his first job, he was given a great posting, but soon after he was given a brand that was not as well reputed as some of the other brands in his company. Vats could have thought of this as a setback to his career growth, but he didn’t. In fact he credits this one challenging posting as something that contributed immensely to his own growth as he expanded his own areas of contribution, and learnt many new skills in the bargain.</p>
    <p>While going through the story below, think of the following:</p>
    <ul>
      <li>Why is it that Sudhanshu Vats says that it was the learnings from this first job that has stayed with him even as a CEO?</li>
      <li>His story showcases that every role you are assigned to gives you the opportunity to make an impact and also learn new things that will stay with you for a long time and contribute to your own growth.</li>
    </ul>
    
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://www.youtube.com/watch?v=LY_ed9nHnYc" />
    </div>
  </div>
}

export default unit;