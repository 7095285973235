import React, { useEffect } from 'react';

let DownloadablePDF = ({ src, updateReadingTracker, resourceId, uid }) => {

  useEffect(() => {
    updateReadingTracker({ uid, resourceId })
  }, [])

  // console.log({ uid, resourceId });
  return <div className='downloadabe-pdf-cntr' style={{ height: '100%' }}>
    <object data={src} width='100%' height='100%'></object>
  </div>
}

export default DownloadablePDF;