import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>In the materials below you will learn some of the techniques people use to apply and adopt the growth mindset in their life. Building the growth mindset involves understanding yourself and your approach to a situation, right from being accountable for what you do to understanding how to handle setbacks and failures.</p>
    
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://www.entrepreneur.com/article/353483" />
      <ExternalResource url="https://www.youtube.com/watch?v=LUtcigWSBsw" />
    </div>
  </div>
}

export default unit;