import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>How do you do your very best? According to Michelle Obama, former First Lady of the United States of America, success is about being consistent, hardworking, conscientious, and a team player. She goes on to say that even when she was hiring people, these are the qualities she repeatedly looked for.</p>
    <p>While going through the video below, think of the following:</p>
    <ul>
      <li>The fact that research says that skills like resilience and conscientiousness can be just as important to building a successful career and life as having a high intelligence quotient (IQ) and grades.</li>
      <li>Why is it that being reliable, understanding other’s views, and serving others is an important characteristic she looks for in people?</li>
    </ul>
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://www.youtube.com/watch?v=VxW5D5VPg_0" />
    </div>
  </div>
}

export default unit;