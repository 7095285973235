import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>Watch the story of Harnek Singh, a messenger in a public sector bank. Even though he held a junior position in the bank he managed to bring fortune to his bank and also helped villagers across many regions.</p>
    <p>The story of Harnek Singh is an important reminder of not feeling constrained by your own position, he did not focus on what he did not have but focused on his own abilities and what he could do for his employer and his community.</p>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://digital-resources.s3.ap-south-1.amazonaws.com/BPUT/Creator+of+destiny/harnek+singh_contributer.mp4' title="Click to see the story of Harnek Singh's Career journey" icon='https://digital-resources.s3.ap-south-1.amazonaws.com/BPUT/Creator+of+destiny/Harnek+singh.JPG' type='video' />
    </div>
  </div>
}

export default unit;