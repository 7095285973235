import React from 'react';
import ExternalResource from '../../../components/ExternalLink';
let unit = (props) => {
    return <div>
        <p>The articles below will give you insights on the way people take ownership of the problems they face and think of unique solutions to meet their goals and focusing on making a concrete change in their own lives.</p>
        <p>In Kaira, Gujarat, when farmers were faced with the challenge of exploitative trade practices, they decided to collectively find a solution to their problems. They achieved this by forming a cooperative, that would give them control over the pricing of their milk and access to the market, thereby eliminating the middleman. This was how the Amul brand was born.</p>
        <p>The farmers of Kaira have time again come together to find solutions to the challenges they have faced, another example of this was when the famous director Shyam Benegal wanted to make a movie on the milk cooperative, but could not find producers for it. The solution to this problem was to finance the film on their own.</p>
        <p>In the articles below, you will learn more about the way solution thinking helped Amul become the beloved brand it is today, while reading the article keep in mind the following:</p>
        <ul>
            <li>The fact that both the farmers and Doctor Verghese Kurien focused on the end goal instead of getting disheartened by the challenges they faced.</li>
            <li>The way in which the making of the movie Manthan showcases the importance of taking ownership of the challenge you are faced with, and ensuring that the end goal is reached.</li>
        </ul>

        <div className='highlighted-patch'>
            <div className='f15_b'>Links</div>
            <ExternalResource url="https://yourstory.com/2016/09/the-story-behind-amul#:~:text=Amul%20was%20formed%20as%20a,to%20the%20then%20Bombay%20government.&text=The%20farmers%20took%20their%20plea,advocated%20farmers'%20cooperatives%20since%201942." />
            <ExternalResource url='https://www.thebetterindia.com/171583/amul-shyam-benegal-farmers-produced-film/' />
        </div>
    </div>
    
    
    
}

export default unit;