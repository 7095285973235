import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>When Ranjitsinh Disale first started teaching , the challenges he faced seemed enormous.  The building he taught in was dilapidated, teenage marriage among his students was common, and since the teaching curriculum was not in Kannada, his students did not understand what was being taught.</p>
    <p>These challenges however, did not faze Ranjitsinh as he took it upon himself to transform his school. He learnt Kannada and translated  the texts for the students, he even embedded QR codes into his learning materials so that students could access videos and audios in their native language. His efforts ensured better results for his students, no more underage marriages in the area and his school even received the prize for the best school in the area.</p>
    <p>Disale’s efforts were recognized globally when he won the $1 million USD Global Teacher Prize. Staying true to his purpose, Disale shared 50% of his award money with fellow nominees so that they all could make a positive impact on education.</p>
    <p>While reading more about Ranjitsinh Disale’s story keep in mind the following:</p>
    <ul>
      <li>How did he stays the course despite all the challenges he faced?</li>
      <li>The fact that his larger purpose was ensuring students across the world had access to quality education, and how he committed his time, energy and considerable money to this purpose.</li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://www.indiatoday.in/education-today/news/story/govt-school-teacher-ranjitsinh-disale-wins-1-million-usd-global-teacher-prize-1746709-2020-12-04' />
      <ExternalResource url='https://edition.cnn.com/2020/12/04/world/global-teacher-prize-scli-intl/index.html' />
    </div>
  </div>
}

export default unit;