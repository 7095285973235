import React from 'react';
import ExternalResource from '../../../components/ExternalLink';
let unit = (props) => {
    return <div>
        <p>Ever wondered how India became an information technology (IT) hub? The answer lies in the story of FC Kohli, former head of Tata Consultany Services (TCS) who helped build the Indian IT industry, and was aptly called the Father of Indian IT.</p>
        <p>At a time when there was no ecosystem or infrastructural support for the development of IT in India, FC Kohli decided to devote time and energy to developing the Indian IT sector. Right from bringing in the latest technology to India, to forging global partnerships FC Kohli put in all his energy into an industry that would help India&rsquo;s growth. Today, the Indian IT sector, is a $190 billion industry all thanks to the groundwork laid by him.</p>
        <p>The articles below speak about his tenacity to create a new industry in India. While reading the following keep in mind:</p>
        <ul>
            <li>The human and social value that FC Kohli brought about through his work. In addition to running a profitable enterprise, he worked with Indian universities to set up Computer Science departments thereby ensuring Indian youth were employable and Indian companies had access to talent when the software industry picked up.</li>
            <li>How FC Kohli&rsquo;s work ensured financial value for TCS by making it a leader in the IT sector, he was not only concerned with the day to day running of the company, but wanted to make a larger impact through his work. He therefore created value at multiple levels through his work.</li>
        </ul>
        <div className='highlighted-patch'>
            <div className='f15_b'>Links</div>
            <ExternalResource url='https://www.livemint.com/companies/people/the-house-that-faqir-chand-kohli-built-11606662430534.html' />
            <ExternalResource url='https://theprint.in/india/fc-kohli-a-true-legend-who-ensured-india-didnt-miss-the-technology-revolution/552788/' />
            <ExternalResource url='https://www.thehindu.com/business/fc-kohli-set-india-on-a-path-that-brought-it-glory/article33187787.ece' />
        </div>
    </div>
}

export default unit;