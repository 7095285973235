import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>Even thirty years after his death, Satyajit Ray is remembered as a master of his craft. His movies are celebrated across the world and every piece of art he has produced has been thought provoking and full of meaning. The articles below talk about Satyajit Ray’s dedication to the very act of filmmaking.</p>
    <p>While going through the articles, keep in the mind the following:</p>
    <ul>
      <li>While other filmmakers in India were merely mimicked Hollywood movies, Satyajit Ray focused on every nuance of filmmaking to create his own original style.</li>
      <li>He would spend hours paying attention to every detail in his movies, what does this say about his dedication to consistently deliver high quality work.</li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://www.thedailystar.net/toggle/news/tribute-satyajit-rays-film-making-techniques-1898776' />
      <ExternalResource url='https://www.sensesofcinema.com/2002/great-directors/ray/' />
    </div>
  </div>
}

export default unit;