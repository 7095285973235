import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>Imagine you are the son of a blacksmith in England in the 18th Century. You have limited access to education and even food is hard to come by. This was how Michael Faraday’s childhood looked. Despite his tough beginnings Faraday went onto become the inventor of electrolysis, electric motors, generators and many more such inventions.</p>
    <p>While going through the articles below, please keep the following in mind:</p>
    <p>At every point in his life, Faraday’s choices were driven by a belief that he could do anything he wanted to do. At 14, when he was an apprentice to a book binder, he not only worked but also used this time to read the books that passed through his hand. These books impacted the way he thought and behaved, for eg. He started conducting science experiments to check the facts in the articles he read.</p>
    <p>Every choice he made helped him grow and widen his own horizons. Despite not having a formal education, Faraday ensured he grasped at every opportunity he got for learning and developing his own knowledge.</p>
    {/* <ul>
      <li>The fact that blaming others will only make you feel more caged and not help you come up with your own solutions to the challenges you are facing.</li>
      <li>How people realize their own inner talents when they focus on developing these talents and realize that it is upto them to do so.</li>
    </ul> */}
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://interestingengineering.com/michael-faraday-a-true-scientific-hero-behind-electromagnetism' />
      <ExternalResource url='https://digital-resources.s3.ap-south-1.amazonaws.com/BPUT/Creator+of+destiny/VtoC-BPUT-Rsources1.pdf' title='Michael Faraday’s journey of becoming a great scientist' icon='https://digital-resources.s3.ap-south-1.amazonaws.com/BPUT/Creator+of+destiny/MICHAEL+FARADAY.JPG' />
    </div>
  </div>
}

export default unit;