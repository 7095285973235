import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>Many of the objects we use in our day to day lives have been invented, the story behind the development of the Gillette razor is that of someone proving naysayers wrong. The Gillette razor started off as an idea in one man’s head and then through his “I Can” approach to building something new and useful, (even when many people thought his ideas were impossible) King C Gillette was able to offer a new invention to the world, one that has become a daily part of our lives today.</p>
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://lemelson.mit.edu/resources/king-gillette" />
    </div>
  </div>
}

export default unit;