import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>These articles will give you an insight into the importance placed on value creation in the world of work. </p>
    <p>Focus on the following while going through the material below:</p>
    {/* <div className='f15_b'>While watching the video, look out for:</div> */}
    <ul>
      <li>Engineer Jeff Parrett ensures success in any project he is working on by ensuring everything he does is innovative, people focused and fun.</li>
      <li>Brands like Tesla attract committed employees and customers because of the outcome they were focused on: building cars that combat climate change. This outcome was considered important, and impactful by many of their stakeholders.</li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://www.youtube.com/watch?v=5hnHqorD1v4' />
      <ExternalResource url='https://medium.com/@afdhelaziz/brand-purpose-101-everything-you-wanted-to-know-but-were-afraid-to-ask-21593fb37d33' />
    </div>
  </div>
}

export default unit;