import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
    return <div>
        <p>Think about the time when the Nokia phone was popular. As a phone it did its job, it was sturdy, reliable and it had games people could play on it. Then came the smartphone, a phone that not only let you call people, but also watch videos, read articles and even conduct school lessons on it. Why is it that Nokia, a popular device manufacturer never managed to transition into the smartphone era? The article below dwells on why Nokia did not manage to stay successful.</p>
        <p>While reading the article, keep in mind the following:</p>
        <ul>
            <li>Was Nokia thinking about the tacit needs of its customers? While Nokia and its engineers focused on building world class hardware, what they completely forgot about was that the needs of the customers were also changing.</li>
            <li>The fact that they only thought of the functional aspects of building a phone but did not think about how a phone could transform the way human beings could and would interact in the future? While Nokia was only building phones, Apple had already started thinking of the uses the phone could have in the lives of people.</li>
        </ul>
        <div className='highlighted-patch'>
            <div className='f15_b'>Links</div>
            <ExternalResource url='https://www.linkedin.com/pulse/dont-nokia-justin-billingsley' />
        </div>
    </div>
}

export default unit;