import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>Who is in charge of your transformation? If you think about it, it is only you who can take charge of your life and ensure you are on the right path. It is you who can create your own destiny according to Charlie Johnson. Johnson talks about his own transformation and how important it is to encourage your own self to grow in the video below.</p>
    <p>To him, change comes from within and it is only the person who is responsible for bringing about that change. The first step towards changing your life for the better is to acknowledge that even when you are faced with any challenge or setback, you still have control over that situation.</p>
    <p>While watching the video, think of the following:</p>
    <ul>
      <li>Charlie Johnson’s approach to owning up and acting positively when you want to transform yourself.</li>
      <li>The importance of constantly holding yourself accountable and ensuring that you stay committed to creating your own destiny.</li>
    </ul>
    
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://www.youtube.com/watch?v=bCteZqlwf-k" />
    </div>
  </div>
}

export default unit;