import React, { useEffect, useState } from 'react';
import { useTransition, animated } from 'react-spring';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { useSelector } from "react-redux";
import Button from '@material-ui/core/Button';
import { updateQuizResponse } from '../../containers/app/redux_actions';
import "./quiz_gtu.css";

const { innerWidth: WinWidth, innerHeight: WinHeight } = window;
let slideHeight, slideWidth;
slideHeight = WinHeight - 72 - 72;
slideWidth = WinWidth - 72 < 420 ? WinWidth - 72 : 420;

let Quiz = (props) => {
  let { myQuizzes, userId } = useSelector(state => state.app);
  let responses = myQuizzes[props.resourceId] && myQuizzes[props.resourceId].responses
    ? myQuizzes[props.resourceId].responses
    : {};
  let quizData = props.quizData || {};

  let isQuizCompleted = myQuizzes[props.resourceId] && myQuizzes[props.resourceId].isQuizCompleted
    ? myQuizzes[props.resourceId].isQuizCompleted : false;

  let isBeingReviewed = myQuizzes[props.resourceId] && myQuizzes[props.resourceId].isBeingReviewed
    ? myQuizzes[props.resourceId].isBeingReviewed : false;
  let showReport = myQuizzes[props.resourceId] && myQuizzes[props.resourceId].showReport
    ? myQuizzes[props.resourceId].showReport : false;

  const [index, setIndex] = useState(isBeingReviewed ? 1 : 0);
  const [errmsg, setErrMsg] = useState(null);
  let introduction = () => {
    if (quizData && quizData.introduction) {
      return <div>
        <div style={{ textAlign: 'center', marginBottom: 16, fontSize: 18, color: '#FFBC36' }}>
          {quizData.title}
        </div>
        <div style={{ fontWeight: 'bold', fontSize: 18, marginBottom: 12 }}>About the Test</div>
        <ul style={{ marginLeft: 16 }}>{quizData.introduction.map((a, i) => {
          return <li key={`intro-${i}`} style={{ margin: '24px 0px' }}><span className='quiz-instruction-list quiz-instruction-list-shadow'></span>{a}</li>
        })}</ul></div>
    } else {
      return null
    }
  }

  const slides = [
    { id: 0, obj: introduction() },
  ];

  const handleChange = ({ qId, oId }) => {
    updateQuizResponse({ userId, quizId: props.resourceId, key: 'responses', value: { ...responses, [qId]: oId } })
  };
  Object.keys(quizData.questions || {}).forEach((k, i) => {
    let el = quizData.questions[k];

    let A = <div key={k}>
      <div style={{ textAlign: 'center', marginBottom: 16, fontSize: 14, color: '#FFBC36' }}>{`${i + 1} of ${Object.keys(quizData.questions).length}`}</div>
      <div>{el.text}</div>
      <FormControl component="fieldset">
        <RadioGroup aria-label={k} name={k} value={responses[k] || null} onChange={(ev) => { handleChange({ qId: k, oId: ev.target.value }) }}>
          {Object.keys(el.options).map(ki => {
            let o = el.options[ki];
            return <FormControlLabel className={o.value ? 'trig' : 'brig'} classes={{ root: 'option-label', disabled: 'option-label-disabled' }} disabled={isBeingReviewed} key={ki} value={ki} control={<Radio />} label={o.text} />
          })}
        </RadioGroup>
      </FormControl>
      {/* {isBeingReviewed ? <div>{el.thinkTrigger}</div> : null} */}
    </div>
    slides.push({ id: i + 1, obj: A });
  });

  const transitions = useTransition(slides[index], item => item.id, {
    from: { opacity: 0 }, enter: { opacity: 1 }, leave: { opacity: 0 },
  });
  // useEffect(() => { setIndex(sceneCount) }, [sceneCount]);

  const onSlideChange = (dir) => {
    if (dir == 1 && index < slides.length - 1) {
      if (Object.keys(responses).length >= index) {
        setIndex(index + 1);
      } else {
        setErrMsg('all questions are compulsory');
        setTimeout(function () { setErrMsg(null) }, 3000);
      }
    } else if (dir == -1 && index > 0) {
      setIndex(index - 1)
    } else if (dir == 1 && index == slides.length - 1) {
      let score = 0;
      Object.keys(responses).forEach(qId => {
        let o = responses[qId]
        let q = quizData.questions[qId];
        let s = q.options[o].value;
        score = score + parseInt(s)
      });
      updateQuizResponse({ userId, quizId: props.resourceId, key: 'showReport', value: true });
      updateQuizResponse({ userId, quizId: props.resourceId, key: 'isQuizCompleted', value: true });
      updateQuizResponse({ userId, quizId: props.resourceId, key: 'score', value: score })
    }
  }
  if (showReport) {
    let reviewFunction = () => {
      updateQuizResponse({ userId, quizId: props.resourceId, key: 'isBeingReviewed', value: true });
      updateQuizResponse({ userId, quizId: props.resourceId, key: 'showReport', value: false });
    }
    return <div style={{ padding: 12 }}>
      <img style={{ maxWidth: '100%' }} src={quizData.report.scorewise[myQuizzes[props.resourceId].score]} />
      <Button onClick={reviewFunction} className="quiz-button-outlined-color" variant="outlined">Check answers</Button>
    </div>
  } else {
    return <div>
      <div style={{ width: WinWidth, height: WinHeight, padding: 36 }}>
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <div className='slide-cntr' style={{ width: slideWidth, height: slideHeight, margin: 'auto', backgroundColor: '#fff' }}>
            {transitions.map(({ item, props, key }) => (
              <animated.div
                key={key}
                className="slide"
                style={{ ...props, position: 'absolute', width: slideWidth, height: slideHeight, overflow: "auto" }}
              >
                <div style={{ height: '100%' }}>{item.obj}</div>
              </animated.div>
            ))}
          </div>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-around', width: slideWidth, margin: 'auto', backgroundColor: '#fff' }}>
              <Button style={{ visibility: index == 0 ? 'hidden' : 'initial' }} className="quiz-button-outlined-color" disabled={index === 0} variant="outlined" onClick={() => onSlideChange(-1)}>Previous</Button>
              <Button className='quiz-button-color' variant="contained" onClick={() => onSlideChange(1)}>{index == slides.length - 1
                ? (isBeingReviewed ? 'Report' : 'Submit')
                : 'Next'}</Button>
            </div>
            <div style={{ height: 10, fontSize: 14, color: '#BA1F00', textAlign: 'center' }}>{errmsg}</div>
          </div>
        </div>
      </div>
    </div>
  }
}

export default Quiz;






let maxRadius = 8;
let dataSet = [
  { name: ['sagar 1', 'sagar 1', 'sagar 1', 'sagar 1', 'sagar 1', 'sagar 1', 'sagar 1', 'sagar 1'], label: 'Victim', value: '1', color: 'red',cx: '1%' },
  { name: ['sagar 2', 'sagar 2', 'sagar 2', 'sagar 2'], value: '2', color: 'red', cx: '25%' },
  { name: ['sagar 3', 'sagar 3', 'sagar 3', 'sagar 3'], value: '3', color: 'red', cx: '65%' },
  { name: ['sagar 4', 'sagar 4', 'sagar 4', 'sagar 4'], label: 'Contributor', value: '4', color: 'red', cx: '95%' }
];

//Assuming the dataSet array is sorted by Value;
let maxValue = Math.max.apply(Math, dataSet.map(function(o) { return o.name.length }));

//maxValue is expected to hold hightest count of the name among all values
let dataSetWithROprops = dataSet.map((el, i)=>{
  let f = el.name.length/maxValue;
  let x = 100/(dataSet.length-1);
  let o = 0;
  if(i==0){
    o = maxRadius*f
  } else if(i==dataSet.length-1){
    o = -maxRadius*f;
  }
  
  return {...el, opacity : f, r: maxRadius*f, cx: `${i*x}%` };
});

//dataSetWithROprops will hold the r and opacity values which can be used :-)











