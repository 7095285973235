import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import store from "../../Redux_Store";

if (!firebase.apps.length) {
  const config = {
    apiKey: "AIzaSyC7wfwLtOf3RhyelZOz9u8jiMzda5jeDGI",
    authDomain: "coaching-platform-illumine.firebaseapp.com",
    databaseURL: "https://coaching-platform-illumine.firebaseio.com",
    projectId: "coaching-platform-illumine",
    storageBucket: "coaching-platform-illumine.appspot.com",
    messagingSenderId: "1002955816731",
    appId: "1:1002955816731:web:0fd922865f30a3f5499dbe",
    measurementId: "G-XS8GS3VLEH",
  };
  firebase.initializeApp(config);
}
let db = firebase.database();

export const setCurrentModule = (md) => {
  store.dispatch({ type: "SET_CURRENT_MODULE", payload: md });
}

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    store.dispatch({ type: "UPDATE_USERID", data: user.uid });

    db.ref(`/users/${user.uid}/userAccount`).on("value", (snapshot) => {
      store.dispatch({ type: "UPDATE_USER_ACCOUNT", data: snapshot.val() });
    });

    db.ref(`/users/${user.uid}/myEngagements/quizzes`).on("value", snapshot => {
      store.dispatch({ type: "UPDATE_USER_QUIZ_ENGAGEMENTS", data: snapshot.val() })
    });

    db.ref(`/users/${user.uid}/mySubscriptions`)
      .orderByChild("app")
      .equalTo("digitalResources")
      .on("value", (snapshot) => {
        let d = snapshot.val();
        if (!d) { store.dispatch({ type: "UPDATE_MODULES", data: null }) }
        Object.keys(d || {}).forEach((el) => {
          let m = d[el];
          db.ref(`modules/${el}`)
            .on("value", (s) => {
              let md = s.val() || {};
              store.dispatch({
                type: "UPDATE_MODULES",
                data: { id: el, detail: md },
              });
              if (md && md.units) {
                Object.keys(md.units).map((uId) => {
                  db.ref(`digitalResources/${uId}`)
                    .once("value")
                    .then((u) => {
                      // console.log(u);
                      let unit = u.val();
                      store.dispatch({
                        type: "UPDATE_UNITS",
                        data: { id: uId, detail: unit },
                      });
                    });
                });
              }
            });
        });
      });
  } else {
    //console.log("no user");
  }
});

export const tabIndexChange = (index) => {
  return {
    type: "TAB_INDEX_CHANGE",
    value: index
  }
}

export const updateReadingTracker = ({ uid, resourceId }) => {
  let updates = {};
  let instanceId = Date.now();
  store.dispatch({ type: "UPDATE_TRACKER" });
  updates[`/users/${uid}/myEngagements/readings/digitalResources/${resourceId}/${instanceId}`] = true;
  db.ref().update(updates);
  return null;
}

// export const chkAccessCode = async (code) => {
//   let a = await db.ref(`/master_otp/${code}`)
//     .once("value");
//   return await a.val();
// }

export const chkAccessCode = async ({ uid, moduleId, code }) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  var urlencoded = new URLSearchParams();
  urlencoded.append("uid", uid);
  urlencoded.append("code", code);
  urlencoded.append("moduleId", moduleId);

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow'
  };
  let a = await fetch("https://us-central1-coaching-platform-illumine.cloudfunctions.net/serverResponse/serviceSessionOTP", requestOptions)
    .then(response => response.text())
    .catch(error => console.log('error', error));
  let a_ = JSON.parse(a);
  console.log(a_);
  let { engagementSessionId } = a_.msg;
  store.dispatch({
    type: "UPDATE_ENGAGEMENT_SESSION_ID",
    data: engagementSessionId,
  });
  return a;
}

export const connectSessionData = ({ uid, sessionId }) => {
  db.ref(`/users/${uid}/myEngagements/sessions/${sessionId}`)
    .on("value", snapshot => {
      let d = snapshot.val();
      store.dispatch({
        type: "UPDATE_SESSIONDATA",
        data: { sessionId, sessionData: { ...d } },
      });
    })
}

export const updateQuizResponse = async ({ userId, quizId, key, value }) => {
  let ref = db.ref(`/users/${userId}/myEngagements/quizzes/${quizId}`);
  var updates = {};
  updates[key] = value;
  return ref.update(updates);
}

export const updateUserEngagement = async p => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  var urlencoded = new URLSearchParams();
  urlencoded.append("uid", p.uid);
  urlencoded.append("sessionId", p.sessionId);
  urlencoded.append("key", p.key);
  urlencoded.append("value", p.value);

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow'
  };
  let a = await fetch("https://us-central1-coaching-platform-illumine.cloudfunctions.net/serverResponse/serviceSessionUpdates", requestOptions)
    .then(response => response.text())
    .catch(error => console.log('error', error));
  return a;
}

// export const updateUserEngagement = (p) => {
//   let { uid, sessionId, key, value } = p;
//   let updates = {};
//   updates[`/users/${uid}/myEngagements/sessions/${sessionId}/${key}`] = value;

//   db.ref().update(updates).catch(err => { console.log(err) });
//   return null;
// };

export const resetSession = () => {
  store.dispatch({
    type: "RESET_SESSION",
  });
}

export const fetchToolData = async (toolId) => {
  let a = await db.ref(`/interactiveTools/${toolId}`)
    .once("value");
  return await a.val();
}