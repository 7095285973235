import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>The workplace is undergoing transformation at a rapid pace. The result of such a high rate of change is that many jobs are becoming obsolete and are replaced by new roles. The articles below give you insight into the new types of jobs that will emerge in the future and the kind of skills these jobs will require. </p>
    <p>While going through the articles below, you may find that the traditional industries of banking and IT are still quite popular, however, there are other industries that are also gaining popularity. Robotics, Digital Marketing, Artificial intelligence, and careers in Alternate energies are some new up and coming fields that can open up interesting career opportunities.</p>
    

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://www.cnbc.com/2019/12/12/linkedin-indias-fastest-growing-jobs-and-the-skills-they-require.html" />
      <ExternalResource url="https://www.naukri.com/blog/10-in-demand-careers-of-the-future-you-should-know-about/" />
      <ExternalResource url="https://koolkanya.com/blogs/career/high-paying-careers-in-india-that-arent-it-or-business-management/" />
    </div>
  </div>
}

export default unit;