import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>When Hindustan Unilever (HUL) decided to expand its reach into rural India, they did so with the dual mission of making profits and also empowering women in rural India. The articles below talk of this move by the multinational to ensure they helped the communities that bought their products.</p>
    <p>While going through the articles below, think of the ways in which empowering women helped HUL and the women concerned.</p>
    
    <ul>
      <li>The fact that by employing women from the community helped establish HUL’s as a trusted brand in the areas they operated in. </li>
      <li>The women earned money, and in turn contributed to their household income which also ensured that their households would aspire to buy the products that HUL sold.</li>
      <li>The fact that this market has contributed significantly to HUL’s profit margins.</li>
    </ul>
    
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://www.businesstoday.in/magazine/special/project-shakti-helped-thousands-of-women-and-also-men/story/195911.html" />
      <ExternalResource url="http://www.businessworld.in/article/HUL-Consumer-Connect/12-02-2020-184025/" />
      {/* <ExternalResource url="https://kalingatv.com/state/pm-modi-hails-odisha-teacher-omprakash-mishra" title='PM Modi hails Odisha teacher Omprakash Mishra | KalingaTV' icon='https://cdn.kalingatv.com/wp-content/uploads/2018/09/Modi-with-Omprakash-Mishra.jpg' /> */}
    </div>
  </div>
}

export default unit;