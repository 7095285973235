import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
    return <div>
        <p>A lot of times the employee &ndash; employer relations may be seen as a transactional one. The employee works, while the employer pays. However, if you infuse this equation with empathy, care and understanding then your company is bound to benefit.</p>
        <p>In order to be an empathetic leader you need to think of the needs of the people you lead, have a keen sense of how they may grow, anticipate their needs and also showcase how they play an important part in the development of the company they work for.&nbsp;</p>
        <p>While reading the articles below keep in mind the following:</p>
        <ul>
            <li>Why is it necessary to truly understand your employees, and what motivates them instead of merely treating them as part of a workforce?</li>
            <li>The fact that companies that give employees a sense of belonging and respect tend to retain them for longer and be successful.</li>
        </ul>
        <div className='highlighted-patch'>
            <div className='f15_b'>Links</div>
            <ExternalResource url='https://www.forbes.com/sites/robdube/2019/12/09/how-kindness--compassion-can-reduce-company-turnover/?sh=4b2b73022845' />
            <ExternalResource url='https://www.businessinsider.in/india/news/digitisation-and-compassionate-leadership-help-companies-recover-from-the-pandemic/articleshow/79799149.cms' />
            <ExternalResource url='https://hbr.org/2010/03/leadership-lessons-from-india' />
        </div>
    </div>
}

export default unit;