import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>The stories below showcase how the zones of contribution expanded for Doctor Aparna Hegde and Doctor Rani Bang. Both were already working in a profession that makes a huge impact on the lives of others. They could be content with their roles as caregivers, but they were constantly reinventing their role, thinking of new ideas and ensuring their work could make a lasting impact on healthcare in India.</p>
    <p>While reading the stories below, pay special attention to the following:</p>
    <ul>
      <li>The way Doctor Aparna Hegde saw a problem and tried to fix it. She was a busy doctor, someone who could have easily stuck to her role as a doctor and only treated her patients. But she turned her role into that of a contributor by thinking about her patients and their lives, and what could be done to improve it.</li>
      <li>Even when Doctor Rani Bang is in a position of authority, she is open to learning, evolving and is not stuck to her role as an expert. This attitude helps her contribute positively to her patients and the surrounding community. </li>
      <li>For both the doctors, can you identify the dynamic identity they took on, and how they expanded their own vision of success? In the end, how have their contributions impacted the domains they work on?</li>
    </ul>
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://ssir.org/articles/entry/deploying_technology" />
      <ExternalResource url="https://www.thebetterindia.com/4060/art-of-healing-among-indias-poorest-a-remarkable-woman-doctors-story/" />
      {/* <ExternalResource url="https://kalingatv.com/state/pm-modi-hails-odisha-teacher-omprakash-mishra" title='PM Modi hails Odisha teacher Omprakash Mishra | KalingaTV' icon='https://cdn.kalingatv.com/wp-content/uploads/2018/09/Modi-with-Omprakash-Mishra.jpg' /> */}
    </div>
  </div>
}

export default unit;