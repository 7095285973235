import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>Elizabeth Holmes, founder of Theranos, started her company when she was 19 years old. She had dropped out of Stanford and had launched a revolutionary product that could drastically cut down the time it took to do blood tests. Through the years she pulled in over $10 billion in investments and was got attention from the media and the business community. The only problem was that her product did not work, and Elizabeth had not only lied to her investors but also endangered the lives of many patients.</p>
    <p>While reading the article below, think of the following:</p>
    
    <ul>
      <li>Elizabeth may have started out with a good idea, however, once she started receiving funding from Silicon Valley and media recognition she seemed to not care as much about the product that she was building, how was it that she started out with a good idea but eventually lost her way?</li>
      <li>Elizabeth Holmes publicly pledged to change lives, but this was a false claim. She was more interested in appearing as someone who would make a difference than actually being a person who made an impact. This is where her roots were not strong, and this attitude eventually caused her downfall.</li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://www.investopedia.com/articles/investing/020116/theranos-fallen-unicorn.asp" />
      <ExternalResource url="https://www.bloomberg.com/news/storythreads/2020-12-03/the-spectacular-rise-and-fall-of-elizabeth-holmes-and-theranos" />
    </div>
  </div>
}

export default unit;