import React from "react";
import { Switch, Route } from "react-router-dom";
import Resources from "./1.Resources.js";
import Resource from "./2.Resource.js";
import Tool from "./3.Tool.js";
import "./redux_actions";

export default () => {
  return (
    <Switch>
      <Route exact path="/app/resource/:resourceId/tool/:toolId/:sessionId?" component={Tool} />
      <Route exact path="/app/resource/:resourceId" component={Resource} />
      <Route exact path="/app" component={Resources} />
    </Switch>
  );
};
