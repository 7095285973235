import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>Social capital, which refers to the value created from investing in good community and human relationships is extremely important to the Tata group. The group has always believed in the motto of “doing well by doing good,” this means that their business practices have always been geared towards positively impacting their shareholders, employees, customers and the community they work in.</p>
    <p>Learn more about how the Tata group has always managed to be a value driven business in the articles below.</p>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      {/* <ExternalResource url="https://www.oxfordleadership.com/good-fail-purpose-driven-business/" /> */}
      <ExternalResource url="https://www.oxfordleadership.com/wp-content/uploads/2019/03/OL-Article-Too-Good-Too-Fail-updated.pdf" title='Too Good to Fail:Purpose-driven Business Pdf' icon='https://digital-resources.s3.ap-south-1.amazonaws.com/icon+for+pdfs.png' />
    </div>
  </div>
}

export default unit;