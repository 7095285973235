import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
    return <div>
        <p>In the video below, researcher, Brene Brown breaks trust down into an acronym, BRAVING.</p>
        <p>&nbsp;This acronym expands to the following:</p>
        <p>B: boundaries</p>
        <p>R: reliability</p>
        <p>A: accountability</p>
        <p>V: vault</p>
        <p>I: integrity</p>
        <p>N: non-judgment</p>
        <p>G: generosity.&nbsp;</p>
        <p>While going through the video, pay attention to the following:</p>
        <ul>
            <li>Importance of being consistent and reliable in every action you take, and why is this important?</li>
            <li>How does owning up to your own mistakes make you more trustworthy?</li>
            <li>Why does Brene Brown say that it is important to practise what you believe in?</li>
        </ul>
        <p><strong>BRAVING (Brene Brown&rsquo;s take) </strong></p>
        <div className='highlighted-patch'>
            <div className='f15_b'>Links</div>
            <ExternalResource url='https://www.youtube.com/watch?v=WuzXTQGFsOw' />
        </div>
    </div>
}

export default unit;