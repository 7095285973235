import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>Will blaming others help you grow or achieve your own goals? The answer to this question lies in the videos below. Both these videos deal with the concept of blaming others when faced with a difficult situation.</p>
    <p>You may think that life is unfair, that things happen to you, but you always have the option of taking charge of the situation. Shifting away from the blaming mindset can really empower you to try new things and in turn make you a creator of your own destiny.</p>
    <p>While going through the videos keep the following in mind: </p>
    <ul>
      <li>The fact that blaming others will only make you feel more caged and not help you come up with your own solutions to the challenges you are facing.</li>
      <li>How people realize their own inner talents when they focus on developing these talents and realize that it is upto them to do so.</li>
    </ul>
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://www.youtube.com/watch?v=IL1JgIj3_fA' />
      <ExternalResource url='https://www.youtube.com/watch?v=AyxhKt94Ebg' />
    </div>
  </div>
}

export default unit;