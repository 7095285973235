import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>Have you ever felt stressed before an exam? Even when you want to study you can’t because you feel overwhelmed or tensed about the outcome of your studying? Now imagine studying because you enjoy the subject, not because you have an exam or you have to get good marks or make your family proud of your achievements.  How do you feel when you engage deeply with a subject? Do you feel more connected to the subject and less stressed? That state of being consumed by what you are doing is known as flow.</p>
    <p>According to psychologist, Mihály Csíkszentmihályi, being in a flow state involves being present in any activity you do. In a state the very act of doing the activity brings you immense pleasure and you think of nothing else but doing the work. This in turn helps you find a deeper connection with what you are doing and ace it.</p>
   
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://www.verywellmind.com/what-is-flow-2794768' />
      <ExternalResource url='https://positivepsychology.com/mihaly-csikszentmihalyi-father-of-flow/' />
    </div>
  </div>
}

export default unit;