import React from 'react';
import ExternalResource from '../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>Solution thinking has tangible impact on the way an organization thinks and eventually acts. According to the study below, due to the introduction of design thinking within the organization, “People talked about envisioning new possibilities together instead of pointing out constraints or defending their personally favored recommendations.” </p>
    <p>In the articles below you will find evidence of why approaches such as design thinking and solution thinking have such a strong demand in the industry. </p>
    {/* <div className='f15_b'>While watching the video, look out for:</div> */}
    <p>You will also learn why hiring creative people, or people who think differently is an important priority for companies today</p>
    {/* <ul>
      <li>The fact that both the farmers and Doctor Verghese Kurien focused on the end goal instead of getting disheartened by the challenges they faced.</li>
      <li>The way in which the making of the movie Manthan showcases the importance of taking ownership of the challenge you are faced with, and ensuring that the end goal is reached.</li>
    </ul> */}

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://www.gobigcreativelab.com/wp-content/uploads/2019/01/Innovative-Design-Thinking-for-Business.pdf" title='Innovative ways companies are using design thinking' description='Jeanne Liedtka' />
      {/* <ExternalResource url="https://www.thebetterindia.com/171583/amul-shyam-benegal-farmers-produced-film/" /> */}
      <ExternalResource url="https://www.ediweekly.com/creative-work-skills-helping-drive-4th-industrial-revolution-fusion-biological-digital-physical/"/>
    </div>
  </div>
}

export default unit;