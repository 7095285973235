import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import store from "../../Redux_Store";

const config = {
  apiKey: "AIzaSyC7wfwLtOf3RhyelZOz9u8jiMzda5jeDGI",
  authDomain: "coaching-platform-illumine.firebaseapp.com",
  databaseURL: "https://coaching-platform-illumine.firebaseio.com",
  projectId: "coaching-platform-illumine",
  storageBucket: "coaching-platform-illumine.appspot.com",
  messagingSenderId: "1002955816731",
  appId: "1:1002955816731:web:0fd922865f30a3f5499dbe",
  measurementId: "G-XS8GS3VLEH",
};

firebase.initializeApp(config);
let db = firebase.database();

db.ref("/settings/fos_explorium").on("value", (snapshot) => {
  store.dispatch({ type: "UPDATE_SETTINGS", payload: snapshot.val() });
});

firebase
  .auth()
  .onAuthStateChanged((user)=> {
    if (user) {
      user.getIdToken(true).then(a=>{
        store.dispatch({ type: "USER_ID_TOKEN", payload: a })
      })
      
      store.dispatch({ type: "LOGIN_SUCCEEDED", payload: user })
    } else {
      store.dispatch({ type: "RESET_APP"});
    }
  });

export const loginAttempt = ({ email, password, successCallBack, failureCallback }) => {
  return dispatch => {
    dispatch({ type: "LOGIN_ATTEMPT", payload: true });
    if (password === "" && email !== "") {
      let errMsg = {};
      errMsg.message = "Please enter a valid password";
      loginAttempFail(dispatch, errMsg, failureCallback);
    } else {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((user) => {
          // loginAttempSuccess(dispatch, user, successCallBack);
        })
        .catch((err) => {
          console.log(err);
          let errMsg = getErrorMsg(err);
          loginAttempFail(dispatch, errMsg, failureCallback);
          setTimeout(() => {
            dispatch({ type: "SET_LOGIN_ERROR_MSG", payload: " " });
          }, 3000);
        });
    }
  };
};

export const registerAttempt = ({
  email,
  password,
  accesscode,
  fullname,
  successCallBack,
  failureCallback,
}) => {
  return (dispatch) => {
    dispatch({ type: "RESET_APP" });
    dispatch({ type: "REGISTER_ATTEMPT", payload: true });
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((user) => {
        let updates = {};
        let uid = user.user.uid;
        updates[`/users/${uid}/userAccount/name`] = fullname || "Anon";
        updates[`/users/${uid}/userAccount/email`] = email || null;
        db.ref().update(updates, (msg) => {
          console.log(msg);
        });
      })
      .catch((err) => {
        let errMsg = getErrorMsg(err);
        console.log(errMsg);
        loginAttempFail(dispatch, errMsg, failureCallback);
      });
  };
};

export const setErrMsg = (el) => {
  return (dispatch) => {
    dispatch({ type: "SET_LOGIN_ERROR_MSG", payload: el });
  };
};

export const resetPassword = (email) => {
  var auth = firebase.auth();
  var emailAddress = email;
  return (dispatch) => {
    if (email) {
      dispatch({ type: "PASSWORD_RESET_IN_PROGRESS", payload: true });
      auth
        .sendPasswordResetEmail(emailAddress)
        .then(function () {
          dispatch({ type: "RESET_PASSWORD_LINK_SENT", payload: true });
        })
        .catch(function (error) {
          let err = getErrorMsg(error);
          setErrorMsgInForgotPassword(dispatch, err.message);
        });
    } else {
      setErrorMsgInForgotPassword(dispatch, "Please enter a valid email id");
    }
  };
};

export const updateUserDetails = (user) => {
  return (dispatch) => {
    dispatch({ type: "UPDATE_USER_UID", payload: user.uid });
  };
};

export const resetLoginPage = () => {
  return (dispatch) => {
    dispatch({ type: "RESET_LOGIN_PAGE" });
  };
};

export const resetForgotPasswordPage = () => {
  return (dispatch) => {
    dispatch({ type: "RESET_FORGOT_PASSWORD_PAGE" });
  };
};

export const registerNewUserPage = () => {
  return (dispatch) => {
    dispatch({ type: "RESET_REGISTER_NEW_USER_PAGE" });
  };
};

export const signOut = () => {
  let auth = firebase.auth();
  return (dispatch) => {
    auth
      .signOut()
      .then(function () {
        dispatch({ type: "LOGOUT_ATTEMPT" });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

/**Helper Functions**/
const loginAttempFail = (dispatch, err, failureCallback, pos) => {
  dispatch({ type: "LOGIN_FAILED", payload: err.message });
  failureCallback();
};

function getErrorMsg(error) {
  let { code } = error;

  switch (code) {
    case "auth/invalid-email":
      error.message = "Please enter a valid email id";
      break;
    case "auth/user-not-found":
      error.message = "This email id is not registered. Please register.";
      break;
    case "auth/wrong-password":
      error.message = "Incorrect email or password. Please try again.";
      break;
    case "auth/email-already-in-use":
      error.message =
        "This email id is already registered. Please use a different email id";
      break;
    default:
      break;
  }

  return error;
}

function setErrorMsgInForgotPassword(dispatch, msg) {
  dispatch({ type: "SET_RESET_PASSWORD_ERROR_MSG", payload: msg });

  setTimeout(() => {
    dispatch({ type: "SET_RESET_PASSWORD_ERROR_MSG", payload: " " });
  }, 3000);
}

function connect({ body, target }) {
  let requestOptions = {
    method: "POST",
    body: JSON.stringify({ ...body }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  return fetch(
    `https://us-central1-fos-explorium.cloudfunctions.net/fos/${target}`,
    requestOptions
  )
    .catch((err) => {
      console.log(err);
    })
    .then((response) => {
      response.json().then((response) => {
        console.log(response);
      });
    });
}
/**End of Helper Functions**/
