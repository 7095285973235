import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
    return <div>
        <p>It may be hard to sympathise with criminals, but that is exactly what the Swedish government did. Their approach to prison reform not only changed the way prisoners are treated but also ensured a significant decrease in crime in Sweden. This was achieved through the power of imaginative sympathy, where prisoners were not looked upon only as criminals or defined by what they had done, but actually viewed as human beings.</p>
        <p>While reading the article below, think of the following:</p>
        <ul>
            <li>The way prison officials looked at their prisoners, not as criminals but as people who needed help to live better lives.</li>
            <li>How this system went beyond functional factors to really look at the human factor, that of enabling individuals instead of imprisoning them.</li>
        </ul>
        <div className='highlighted-patch'>
            <div className='f15_b'>Links</div>
            <ExternalResource url='https://www.theguardian.com/society/2014/nov/26/prison-sweden-not-punishment-nils-oberg' />
        </div>
    </div>
}

export default unit;