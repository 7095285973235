import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>In the stories below you will find examples of contributors. These are everyday people from the state of Odisha who have done extraordinary things just by committing to making a difference.</p>
    <p>While reading the stories below think of the following:</p>
    <p>The articles below speak about his tenacity to create a new industry in India. While reading the following keep in mind:</p>
    <ul>
      <li>The way Nirajalaxmi Mohapatra went beyond her assigned job role as a counsellor to inmates. She saw the plight of the children of these inmates and decided to do something to help them.  </li>
      <li>The fact that students, Rajat Mahakuda, Jayesh Acharya, Ashutosh Nahak, Nihal Vineesh, Rebika Sahu and Indu Kumari Sahoo were unperturbed by Covid19,and decided to start entrepreneurial ventures during the free time they had during the lockdown. </li>
      <li>Omprakash Mishra’s contribution to his students and the school he worked in, the efforts he took to ensure children not only came to school but also studied well. </li>
      <li>Also think of all the ways the people featured below have contributed to society, their family and their state? </li>
    </ul>
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://yourstory.com/2016/06/odisha-hostel-convicts-children" />
      <ExternalResource url="https://kalingatv.com/state/pm-modi-hails-odisha-teacher-omprakash-mishra" title='PM Modi hails Odisha teacher Omprakash Mishra | KalingaTV' icon='https://cdn.kalingatv.com/wp-content/uploads/2018/09/Modi-with-Omprakash-Mishra.jpg' />
    </div>
  </div>
}

export default unit;