import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
    return <div>
        <p>Everyone is aware of the Sahara scam, where the conglomerate was fraudulently raising money for its companies. Sahara lost the trust of its patrons and customers, but at the same time India&rsquo;s regulatory bodies and judicial bodies showcased integrity and an ability to do right by investors. The Securities Exchanges Board of India and the Supreme Court were both instrumental in ensuring Sahara paid the price for its wrongdoing.</p>
        <p>While going through the articles below, think of the following:</p>
        <ul>
            <li>How strong, trustworthy institutions can ensure even the most powerful companies are punished for their crimes.</li>
            <li>The integrity showcased by SEBI and its constant persistence in ensuring Sahara was punished.</li>
        </ul>

        <div className='highlighted-patch'>
            <div className='f15_b'>Links</div>
            <ExternalResource url='https://economictimes.indiatimes.com/opinion/et-commentary/sahara-a-landmark-case-that-brought-focus-on-investor-protection/articleshow/31658922.cms?from=mdr' />
            <ExternalResource url='https://timesofindia.indiatimes.com/business/india-business/sahara-cannot-be-trusted-any-more-supreme-court/articleshow/24827352.cms' />
        </div>
    </div>
}

export default unit;