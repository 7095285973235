import React from 'react';
import { useSelector } from "react-redux";
import Quiz from '../../components/Quiz';

let Tool = (props)=>{
 let uid = useSelector((state) => state.app.userId);
 return <div>
  <Quiz uid={uid} {...props}/>
 </div>
}

export default Tool;