import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>The stories below are of everyday people trying to make a difference to their communities. In the absence of monetary resources, it is innovation and their will to better the lives of those around them that drives them to contribute.</p>
    <p>While reading the following, pay special attention to:</p>
    <ul>
      <li>The innovations Kamlesh Zapadiya has come up with to solve challenges his students face. The fact that he did not wait for assistance from the government or any authority but built a solution on his own with limited means. </li>
      <li>In all the three stories from Pakistan, Philippines and India, the protagonists in the story see hardships around them, they do not complain or wait for others to help, instead they take it upon themselves to make an impact.</li>
    </ul>
    
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://www.thebetterindia.com/12097/man-travels-20-kms-every-day-just-make-education-interesting/" />
      <ExternalResource url="https://www.youtube.com/watch?v=Zm-KjkdA2UY" />
      {/* <ExternalResource url="https://kalingatv.com/state/pm-modi-hails-odisha-teacher-omprakash-mishra" title='PM Modi hails Odisha teacher Omprakash Mishra | KalingaTV' icon='https://cdn.kalingatv.com/wp-content/uploads/2018/09/Modi-with-Omprakash-Mishra.jpg' /> */}
    </div>
  </div>
}

export default unit;