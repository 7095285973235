import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
    return <div>
        <p>The dictionary definition of trust says that trust is, &ldquo;feeling safe when vulnerable,&rdquo; but how does one gain trust or lose trust? The article below gives you an insight into how you can build trust in the way you communicate with others by offering six building blocks of trust.</p>
        <p>While going through the article, think of the following:</p>
        <ul>
            <li>Why is it that people with good intentions may not be able to win the trust of others?</li>
            <li>The importance of acknowledging the wants and needs of others while building trust?</li>
            <li>Why do people perform better in a high trust environment?</li>
        </ul>
        <div className='highlighted-patch'>
            <div className='f15_b'>Links</div>
            <ExternalResource url='https://www.forbes.com/sites/dennisjaffe/2018/12/05/the-essential-importance-of-trust-how-to-build-it-or-restore-it/?sh=5b5288e464fe' />
        </div>
    </div>
}

export default unit;