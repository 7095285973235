import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
    return <div>
        <p>The following story is about what happens when there is complete breakdown of trust between a country and its people.</p>
        <p>The images of the Lebanon blast are strongly imprinted on everyone&rsquo;s mind, one moment you see flames, the next moment a strong blast destroys the city of Beirut, Lebanon. The reason behind the blast was 2,700 tonnes of ammonium nitrate which sat at the Beirut port for years without anyone doing anything about it. The blast left over two hundred people dead and 300,000 people homeless. One key factor that contributed to this immense loss was the fact that the Lebanese government and bureaucracy had not been transparent about the storage of ammonium nitrate, this was a severe breach of trust by the government.</p>
        <p>While going through the article below, think of what could have been done differently by the government?</p>
        <ul>
            <li>Would firefighters have been sent to the port if the port authorities had been transparent that the cause of the fire was the ammonium nitrate that had been stored?</li>
            <li>The ammonium nitrate had been stored at the port for seven years, but no one in the government took any appropriate action to move the explosive material and ensure safety of the citizens, this lack of transparency and inability to share information ultimately lead to loss of lives.</li>
        </ul>
        <div className='highlighted-patch'>
            <div className='f15_b'>Links</div>
            <ExternalResource url='https://www.youtube.com/watch?v=JGgOjGnykRE' />
            <ExternalResource url='https://www.cbc.ca/radio/asithappens/as-it-happens-the-thursday-edition-1.5676573/lebanese-people-have-complete-lack-of-trust-in-beirut-blast-investigation-says-human-rights-watch-1.5677031' />
        </div>
    </div>
}

export default unit;