import React from 'react';
import ExternalResource from '../../../components/ExternalLink';
let unit = (props) => {
    return <div>
        <p>In the video below Eddie Woo talks about his love for teaching mathematics. Eddie did not grow up loving mathematics, in fact in school he was deeply interested in studying subjects related to humanities and arts. So, what made Eddie go from disliking maths to liking it so much that he became a mathematics teacher? Find out in the video below:</p>
        <p>While going through the video, think of the following:</p>
        <ul>
            <li>How did he form his own unique understanding of mathematics?</li>
            <li>He asks the audience to see mathematics in nature (a river and a tree), how is this different from the way one normally talks about or views math?</li>
            <li>How is his engagement with mathematics different from a teacher who would simply follow a syllabus? How would his approach help students engage with mathematics?</li>
        </ul>
        <div className='highlighted-patch'>
            <div className='f15_b'>Links</div>
            <ExternalResource url='https://www.youtube.com/watch?v=PXwStduNw14' />
        </div>
    </div>
}

export default unit;