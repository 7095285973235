import React from 'react';
import ExternalResource from '../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>Typically, in the money-lending business, a person can borrow money based on howcapable s/he is in repaying loan. However, this cuts off people who have “nothing” because of their current state.</p>
    <p>Dr. Muhammad Yunus, a social entrepreneur, wondered how poverty can be eradicated if such people don’t have access to any financial services like loans.</p>
    {/* <div className='f15_b'>While watching the video, look out for:</div> */}
    <p>He asked...</p>
    <ul>
      <li>Can we take the bank to the people instead of people coming to the bank?</li>
      <li>Can we lend a person on the basis of trust?</li>
      <li>Can we use the community to assess the trust of the person?</li>
    </ul>
    <p>The solution that he built changed the paradigm of banking. He built a model called “micro-finance “where small loans would be given to people with less than 1$ a day income, who would invest the amount into small entrepreneurial ventures like food stall, cloth mending shop, etc. to generate more income. Today this is known as ‘Grameen Bank’ which has branches in developed nations too.</p>
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://www.bbc.com/news/world-south-asia-11901625" />
    </div>
  </div>
}

export default unit;