import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>Creating value means making a tangible impact in a situation. It also means being devoted to your purpose and standing by it even when you are faced with challenges. The athletic wear brand Nike decided that profits were not as important as human lives when they showed support for the Black Lives Movement and racial justice in the United States of America. This was done with the help of an advertisement featuring Colin Kaepernick, a footballer who was a vocal supporter of the movement.</p>
    <p>While reading the articles below keep in mind the following: </p>
    <ul>
      <li>Nike realized that a brand as big as theirs had the power to influence positive change and positively impact the lives of millions of people. </li>
      <li>Nike was devoted to being on the right side of history, and this strengthened their brand image and ensured a spike in their growth, soon after the advertisement was launched. </li>
    </ul>
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://www.forbes.com/sites/afdhelaziz/2018/09/04/the-power-of-purpose-nike-and-colin-kaepernick/?sh=4d6263436f33" />
      <ExternalResource url="https://www.marketplace.org/2020/06/25/nike-colin-kaepernick-brand-advertising-racial-justice/" />
      <ExternalResource url="https://www.reutersevents.com/sustainability/why-nike-was-right-feature-colin-kaepernick-its-controversial-new-ad" />
    </div>
  </div>
}

export default unit;