import React from 'react';
import ExternalResource from '../../../components/ExternalLink';
let unit = (props) => {
    return <div>
        <p>In the era of WhatsApp, Facebook and Instagram it is very easy to think of every information you receive as credible. But some of the information can be extremely harmful and even life threatening. The articles below talk about the spread of misinformation related to Covid19 and vaccination, and showcases some of the ways in which people are falling for this misinformation, while going through the articles below think of the following:</p>
        <ul>
            <li>What kind of thinking through would be needed to fight the anti-vaccination movement in India?</li>
            <li>Thousands of Indians are falling prey to misinformation spread by YouTube Doctors who are not qualified to be Doctors, are they thinking for themselves when they believe these doctors or simply believing all the information they receive?</li>
            <li>How would looking at research reports, verifying information from expert sources and thinking for themselves help them?</li>
            <li>Why is important to understand and engage with every piece of information presented to you, especially when they are not coming from official sources or from experts?</li>
        </ul>

        <div className='highlighted-patch'>
            <div className='f15_b'>Links</div>
            <ExternalResource url='https://timesofindia.indiatimes.com/india/how-vaccine-myths-are-spreading-in-india/articleshow/79723097.cms' />
            <ExternalResource url='https://restofworld.org/2020/the-doctor-will-mislead-you-now/' />
        </div>
    </div>
}

export default unit;