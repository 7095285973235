const INITIAL_STATE = {
  settings: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "Navigation/NAVIGATE":
      return state;
    case "UPDATE_SETTINGS":
      return { ...state, settings: action.payload };
    default:
      return state;
  }
}