import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>We all have heard of the ‘Metro Man’ E. Sreedharan. He is credited with changing the face of public transport in India. In the interviews below, you get insights into the way E. Sreedharan thinks, and his attitude to his work, his team, and society in general.</p>
    <p>While going through the links, pay special attention to:</p>
    <ul>
      <li>The fact that E Sreedharan leads by example and does not rest on his laurels or think of himself as a high ranking official. He does not want to sit in an air-conditioned room and make others do all the work, he is actively contributing to his work “Here I try to set an example in all manners, everything, whether it is punctuality or inspections or the standards for specifications, finishing of the work, anything,” he says.</li>
      <li>To him, the metro is not just a project, he does not view himself only as an engineer building something, and instead, he views his work as a service that impacts the lives of many people.</li>
    </ul>
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://www.thehindu.com/news/national/metro-man-e-sreedharan-on-being-a-guiding-force-for-infrastructure-projects/article28806393.ece' />
      <ExternalResource url='https://india.blogs.nytimes.com/2011/10/05/a-conversation-with-e-sreedharan/' />
    </div>
  </div>
}

export default unit;