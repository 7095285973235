import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>Value is a mode of thinking that involves being driven by purpose, usually to make a difference in the lives of other people and society at large. At an individual level or at a company level, this drive to make an impact is extremely crucial. In the articles you will get a better understanding of how you can develop value thinking for yourself. </p>
    <p>The material below will help you understand: </p>
    <ul>
      <li>What does it take to become a person of value?</li>
      <li>What does it mean when the video says that a values focused life ensures better fulfilment while achieving your goals?</li>
      <li>The difference between merely performing your duties and creating value at work.</li>
    </ul>
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://www.linkedin.com/pulse/how-become-person-value-influence-james-hurford/" />
      <ExternalResource url="https://www.youtube.com/watch?v=eiPxLpYlw4I" />
      <ExternalResource url="https://www.forbes.com/sites/cywakeman/2015/02/24/the-new-value-equation-does-your-team-really-measure-up/?sh=ef658f24281a" />
    </div>
  </div>
}

export default unit;