import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>In the video below you get a peek into the life and career of an IAS officer from former IAS Officer Vivek Atray. Right from taking important decisions, to handling the media and politicians, an IAS officer takes on many roles through his career. He needs to be a visionary, he needs to be a changemaker and also a manager. </p>
    <p>While listening to the video, think of the following:</p>
    <p>According to Vivek Atray, the civil services needs an all-rounder, some of the skills that are needed are good communication skills, empathy and even an ability to be humorous. </p>
    <p>Understanding and appreciating people is an important part of being an IAS officer.</p>
    <p>In his stories, he also talks about the power of adapting to situations, think of the situation (Governor attending an event), and how it helped him build his own confidence and ability to handle many different kinds of situations. </p>
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://www.youtube.com/watch?v=FMEYsgFoupw" />
      {/* <ExternalResource url="https://kalingatv.com/state/pm-modi-hails-odisha-teacher-omprakash-mishra" title='PM Modi hails Odisha teacher Omprakash Mishra | KalingaTV' icon='https://cdn.kalingatv.com/wp-content/uploads/2018/09/Modi-with-Omprakash-Mishra.jpg' /> */}
    </div>
  </div>
}

export default unit;