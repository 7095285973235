import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>M.S. Swaminathan is a scientist who is well known across India for the important role he played in leading the country’s green revolution. The green revolution resulted in the country becoming self-sufficient in food production, thereby ensuring the country had a steady supply of food.</p>
    <p>Swaminathan’s journey is a strong example of how one can unleash their own power to contribute in any role. Swaminathan was passionate about helping India, he even joined the Quit India movement but soon realized that it is through science that he could serve the nation better. He then went on to earn a PhD from the University of Cambridge, and later set up a potato research station at the University of Wisconsin. However, when offered a full time faculty position at the University of Wisconsin, he declined because he had gained all this knowledge so that he could come back to India and help the country. It is through his efforts that India became a self-sufficient when it came to agricultural produce. </p>
    <p>However, M.S. Swaminathan’s role contribution did not stop with this achievement. While reading the article below, think of the following:</p>
    <ul>
      <li>He is constantly trying to improve and expand his contribution to farmers – he is now working on an ‘evergreen revolution’ to ensure sustainable agriculture. </li>
      <li>He does not see his work as a fixed set of roles but is constantly seeking out new ways in which he can contribute to people across the nation – right from understanding tribal practises in farming to leveraging IT for fishermen.</li>
    </ul>
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://www.mansworldindia.com/currentedition/from-the-magazine/ms-swaminathan-green-revolutionary/" />
      <ExternalResource url="https://timesofindia.indiatimes.com/city/chandigarh/father-of-green-revolution-gives-call-for-evergreen-revolution/articleshow/58200698.cms" />
    </div>
  </div>
}

export default unit;