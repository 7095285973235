import React from 'react';
import ExternalResource from '../../../components/ExternalLink';
let unit = (props) => {
    return <div>
        <p>Solution thinking has a tangible impact on the way an organization thinks and eventually acts. The articles below will give you more information on why hiring creative people, or people who think differently is an important priority for companies today. </p>
        
        <div className='highlighted-patch'>
            <div className='f15_b'>Links</div>
            <ExternalResource url='https://www.ediweekly.com/creative-work-skills-helping-drive-4th-industrial-revolution-fusion-biological-digital-physical/' />
        </div>
    </div>
}

export default unit;