import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>Carol Dweck’s mindset theory showcases how fixed mindsets and growth mindsets can impact the way people view their own personality and intelligence. Learn more about the difference between the fixed and growth mindset and how they impact your career from the article below.</p>
    <p>While reading this article, pay special attention to</p>
    <ul>
      <li>The difference between fixed and growth mindset</li>
      <li>Understanding the value of facing challenges instead of shying away from them</li>
    </ul>
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://fs.blog/2015/03/carol-dweck-mindset/' />
      <ExternalResource url='https://www.youtube.com/watch?v=rUJkbWNnNy4 ' />
      <ExternalResource url='https://www.linkedin.com/pulse/important-mindset-make-you-more-successful-alistair-cox/' />
    </div>
  </div>
}

export default unit;