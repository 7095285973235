import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>In the new world of work, fixed approaches to working are breaking down. As one enters the workforce, it is likely, you will not have fixed roles, fixed deliverables or a stable team.  Learn more about how agile teams are formed and deliver results with the right mindset, from Amy Edmondson.</p>
    <p>While watching the video, look out for:</p>
    <ol>
      <li>The personal qualities that are needed to function well in an agile team </li>
      <li>How does having a dynamic identity contribute to making a better team mate?</li>
    </ol>
    
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://www.youtube.com/watch?v=3boKz0Exros" />
    </div>
  </div>
}

export default unit;