import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import Login from "./Login";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";

import "./auth.css";
import { useMediaQuery } from "@material-ui/core";

let Auth = () => {
  const isDesktop = useMediaQuery("(min-width:980px)");

  return (
    <div
      className={isDesktop ? "desktop auth" : "mobile"}
      style={{ width: "100%" }}
    >
      <Switch>
        <Route path="/auth/login" component={Login} />
        <Route path="/auth/register" component={Register} />
        <Route path="/auth/reset-password" component={ForgotPassword} />
        <Route path="/auth/" component={Login} />
      </Switch>
    </div>
  );
};

export default Auth;
