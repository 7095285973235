import React from 'react';
import ExternalResource from '../../../components/ExternalLink';
let unit = (props) => {
    return <div>
        <p>Much of our modern world is dependant on plastic. From mobile phones, television, to other electronics; from disposable bags, file covers to storage cabinets - plastic is everywhere. It is valuable because it is light, durable, hygienic and inexpensive. Plastic made it possible to make health care simpler, hygienic, less painful and affordable, MRI machines, disposable syringes, intravenous blood bags, and even heart valves are now made of plastic.</p>
        <p>Despite being low priced and extremely valuable to the modern world, the usage of plastic has also done irreversible damage to the environment. Plastic pollutes our oceans and damages our marine life, The Great Pacific Garbage Patch, deep in the pacific ocean, four times the size of India, is filled with trash consisting mostly of plastic debris. It causes millions of deaths of marine life when they ingest plastic or get tangled in it. Plastic also causes a lot of harm to our bodies when plastic slowly leaks a toxic chemical bisphenol into our food. This toxic chemical is known to disrupt reproductive functions and also been linked to prostate cancer, miscarriages and birth defects.</p>
        <p>The articles below talk about both the positive and negative value of plastic. While reading the articles think of the following:</p>
        <ul>
            <li>What are some of the value-creating and value destroying properties of plastic?</li>
            <li>What are some of the solutions that you can think of to help solve the plastic challenge in the world?</li>
        </ul>
        <div className='highlighted-patch'>
            <div className='f15_b'>Links</div>
            <ExternalResource url='https://www.npr.org/sections/thesalt/2014/07/24/334617901/the-weird-underappreciated-world-of-plastic-packaging' />
            <ExternalResource url='https://www.bbc.com/news/business-42646025' />
        </div>
    </div>
}

export default unit;