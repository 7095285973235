import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>In the article below, Harvard Business School (HBS) professor Howard Stevenson HBS and senior research fellow Laura Nash talk about the characteristics of high achievers. According to them, these are achievement, happiness, significance, and a legacy. What is important to note while reading the article below is that according to the researchers, most successful people juggle between these four characteristics.</p>
    <p>What does juggling look like?</p>
    <p>While watching the video, think of the following:</p>
    <ul>
      <li>This means, they are not just committed to achieving their goals, instead they are happy to be on the journey that ensures they build something for the future.</li>
      <li>They do not seek success for themselves only, they are interested in making an impact.</li>
      <li>They do not seek happiness from short-term success or factors like money or prestige, instead they are more focused on building something that is long-lasting.</li>
    </ul>
    
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://hbswk.hbs.edu/item/four-keys-of-enduring-success-how-high-achievers-win" />
    </div>
  </div>
}

export default unit;