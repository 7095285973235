import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>What are some of the steps you will need to take on your journey to being a future ready contributor? According to the speaker in the video below, Jim Cathcart, to become a contributor, you must learn more about yourself, and understand what your behaviour patterns and choices are.</p>
    <p>Listen to Jim’s story below to understand:</p>
    
    <ul>
      <li>How he went from being a government clerk to an entrepreneur.</li>
      <li>How did he devote time to learning something new and engage deeply with a certain subject, and how did this transform his own life.</li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://www.youtube.com/watch?v=-ki9-oaPwHs" />
    </div>
  </div>
}

export default unit;