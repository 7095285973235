import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>Lakshmi Menon’s story of change-making began with trying to address an issue many poor Indians face, finding cheap bedding when they cannot even afford a roof over their head. The need for a cheap bedding solution increased during the Covid19 crises when quarantine centres were instructed to burn their mattresses. Lakshmi, a designer by profession, saw a design challenge and went about seeking to address it. In doing so she managed to create an new innovation that has helped people immensely. </p>
    <p>While reading the story below, please pay attention to the following:</p>

    <ul>
      <li>As a designer, Lakshmi thought her job was to find innovative solutions to design problems. This drove her to design a low cost, recycled mattress that could be easy to clean. </li>
      <li>She managed to think of a solution that brought change at multiple levels and made life better for many people. </li>
      <ul>
        <li>She created job opportunities in her community. </li>
        <li>She also helped reduce the damage made to the environment. </li>
        <li>She also cut costs for the government. </li>
      </ul>
      <li>The personal satisfaction she got from finding a solution to this problem. </li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://youtu.be/A0FXs6xIHAc" />
      {/* <ExternalResource url="https://kalingatv.com/state/pm-modi-hails-odisha-teacher-omprakash-mishra" title='PM Modi hails Odisha teacher Omprakash Mishra | KalingaTV' icon='https://cdn.kalingatv.com/wp-content/uploads/2018/09/Modi-with-Omprakash-Mishra.jpg' /> */}
    </div>
  </div>
}

export default unit;