import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>The articles below tell the story of the Chairman of Cipla YK Hameid. Back in 2001, when major pharmaceutical companies were charging a high price for anti retroviral drugs, Hameid decided that human life was more important than mere profits. In doing so, he challenged many goliaths in the pharmaceutical business, but in the end it was due to this move that prices for the drugs were slashed the world over.</p>
    <p>While going through the articles below, think of the following:</p>
    <p>The fact that Hameid thought of the collective by not only saving lives but also leading to an industry wide transformation.</p>
    
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://www.ft.com/content/47776b3c-4d78-3e82-b626-c2af62558c4f" />
      <ExternalResource url="https://www.forbesindia.com/article/leaderhip-awards-2012/yk-hamied-ciplas-oneman-army/33881/1" />
    </div>
  </div>
}

export default unit;