import React from 'react';
import ExternalResource from '../../../components/ExternalLink';
let unit = (props) => {
    return <div>
        <p>Can you learn the basics of tennis in twenty minutes when you have never played it before? Timothy Galway introduced a new way of teaching and learning when he taught his students to ask the player not to hit the ball but to notice the place where the ball and the racquet made a connection. Players had to focus deeply to understand where this connection happened. What did this to the tennis player was to remove all other voices in the mind.</p>
        <p>&nbsp;</p>
        <p>While going through the material below think of the following:</p>
        <p>&nbsp;</p>
        <ul>
            <li>Timothy Galway was seeking clarity to deeper understand what went on in the mind of the player, not just looking at external technique.</li>
            <li>How did Galway&rsquo;s novel approach to sports training change the way people played sports?</li>
        </ul>

        <div className='highlighted-patch'>
            <div className='f15_b'>Links</div>
            <ExternalResource url='https://www.youtube.com/watch?v=9s2sVPS99-A' />
            <ExternalResource url='https://www.amazon.in/Inner-Game-Tennis-Classic-Performance/dp/0679778314' />
        </div>
    </div>
}

export default unit;