import React from 'react';
import ExternalResource from '../../../components/ExternalLink';
let unit = (props) => {
    return <div>
        <p>The following case study showcases solution thinking in action. </p>
        <p>Traffic congestion is a complex problem that plagues any big city. Elon Musk,
        a visionary entrepreneur, realizing how much worse this problem could become in future, thought, “Why can’t we go underground and build a network of stacked tunnels that can transport people and cars on sled to their destinations, at speeds of up to 130 miles per hour, without getting stuck in jams?”</p>

        <p>Today his company (called ‘The Boring Company’) has started with Los Angeles as their first test case. They have begun boring tunnels and creating the first prototype elevator that can take cars underground & help cover a 60 min. journey in 5-10 mins.</p>
        <p>So what makes Elon Musk think differently? Learn more about Elon Musk’s approach to problems and challenges in the video below. While watching the video, pay special attention to how he applies first principles thinking to ideation. </p>

        <div className='highlighted-patch'>
            <div className='f15_b'>Links</div>
            <ExternalResource url='https://www.youtube.com/watch?v=xSwxQedUstw' />
        </div>
    </div>
}

export default unit;