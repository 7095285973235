import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>In this video, Varun Agarwal shares his career story. Despite facing failures and not having family support, Varun managed to build a successful filmmaking career because he was passionate about it. It was this internal drive to succeed on his own terms that drove him to excellence. </p>
    <p>While watching the video, keep in mind:</p>
    <ul>
      <li>What Varun means when he says, “stop thinking so much.”</li>
      <li>His derives immense satisfaction because he has created something new. Through his work Varun is able to see his ideas become a reality.</li>
    </ul>
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://www.youtube.com/watch?v=nMPqsjuXDmE' />
    </div>
  </div>
}

export default unit;