//import { combineReducers } from 'redux'
import authReducers from "./auth/redux_reducers";
import appReducers from "./app/redux_reducers";
import PublicReducers from "./public/redux_reducers"

let reducers = {
  auth: authReducers,
  app: appReducers,
  public: PublicReducers
};

//export default combineReducers(reducers)
export default reducers;