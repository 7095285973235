import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>In the video below, Indira Krishnamoorthi, Senior Data Scientist at Brillio, offers some interesting insights into the kind of new career pathways that are available in the world of Data Science. She lays out some of the qualities you need, to function in a completely new industry and also talks about the hard skills and soft skills needed for the job.</p>
    
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://www.youtube.com/watch?v=4mVcZWm3pd0&feature=youtu.behttps://www.youtube.com/watch?v=4mVcZWm3pd0&feature=youtu.be" />
    </div>
  </div>
}

export default unit;