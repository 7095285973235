import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>In the video below, development economist Paul Collier talks about the need for enlightened self interest.</p>
    <p>A key idea for you to engage with in this talk is as follows: </p>
    <p>He makes an important differentiation between compassion and enlightened self-interest. While the former makes you care about a situation, the latter makes you channel that compassion into something that can positively impact you and those you are trying to help. </p>
    <p>Also focus on the wide-ranging consequences of acting out of enlightened self – interest:</p>
    <p>The example he gives of the United States of America helping Europe after World War 2, and how this alliance eventually benefited both regions and their people. </p>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://www.youtube.com/watch?v=VhAD0dMslB8' />
    </div>
  </div>
}

export default unit;