import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>The advent of social media has changed the way all of us consume news. We no longer get our news from newspapers or news channels  only.  WhatsApp, Facebook and twitter are all sources of news for people today. Fake news is the result of vested interests and lack of engagement from readers.  If readers only read news to confirm their bias then people will write what the readers want instead of digging deeper to understand the truth.</p>
    <p>While reading the articles below think of the following:</p>
    <ul>
      <li>The importance of media literacy and critically evaluating everything you read</li>
      <li>What purpose does peddling fake news serve and why should people fight it?</li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://www.nytimes.com/2020/02/20/education/learning/news-literacy-2016-election.html' />
      <ExternalResource url='https://www.thehindu.com/opinion/columns/trust-in-the-age-of-misinformation/article30550937.ece' />
    </div>
  </div>
}

export default unit;