import React from 'react';
import ExternalResource from '../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>While going through the articles below, think of the ways in which you can adopt some of these methods in your day to day life.</p>
    <p>Some of the methods that have been showcased are as follows:</p>
    <ul>
      <li>Creative Problem Solving (CPS) method to develop creative solutions to problems. This method puts creativity at the forefront instead of evaluation. A good time to use this method is when you face a problem that seemingly does not have any solution</li>
      <li>Design thinking which helps you take a human centred approach to problems. The design thinking method can help you solve challenges from a human point of view while also keeping economics and technology in mind.</li>
      <li>Theory of Inventive Problem Solving (TRIZ): Learn how you can use the TRIZ, a method that employs  logic, data and research to solve problems creatively.</li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://www.lucidchart.com/blog/how-to-use-creative-problem-solving-at-work" />
      <ExternalResource url="https://www.ideou.com/pages/design-thinking" />
      <ExternalResource url="https://www.mindtools.com/pages/article/newCT_92.htm"/>
      <ExternalResource url="https://www.youtube.com/watch?v=IDrdFIGzDUg"/>
    </div>
  </div>
}

export default unit;