import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>In the stories below, you will read about two different employees who have been nominated by their companies as awesome people to work with.</p>
    <p>Please note that Deepak Dhar from Citrus Pay and Korath Paul from Freecharge has a lot of characteristics in common.</p>
    <p>While going through the videos keep the following in mind: </p>
    <ul>
      <li>They are both passionate about their work.</li>
      <li>They are willing to put all their time and energy into doing the best they can do.</li>
      <li>They are both extremely excited about being part of their respective companies.</li>
      <li>They have both developed the roots required for success.</li>
      <li>They ensure that every project teaches them something new.</li>
      <li>They are willing to help others and are extremely loved by their co-workers.</li>
    </ul>
    <p>While reading the stories below, think of the following:</p>
    <ul>
      <li>The fact that Korath Paul is beloved in his company for being dependable, reliable, and helpful. In fact, after joining Freecharge he even helped his co-workers understand a product better. In addition to this Korath is also excited to take on new challenges and considers every problem he faces at the workplace an opportunity to learn and grow.</li>
      <li>Deepak Dhar joined a smaller company because he wanted his contribution to matter and not be just another employee. He also always maintains a great attitude even when he is faced with a long working day.</li>
    </ul>
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://yourstory.com/2015/08/awesome-startup-employee-freecharge' />
      <ExternalResource url='https://yourstory.com/2014/06/awesome-startup-employee-deepak-dhar/amp/' />
    </div>
  </div>
}

export default unit;