import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>The article below tries to understand the reason behind the phenomenon of Indian origin people leading some of the largest companies of the world and the unique thinking they bring to the table that helps drive their companies. Take for example the story of Satya Nadella who drove innovation in Microsoft by focusing on empathy.  It is through empathy, he realized the true purpose of Microsoft was to “build technology so that others could build more technology.” This helped set a new direction for the company to grow in. </p>
    <p> Read the article below to understand the following:</p>
    
    <ul>
      <li>What is the reason behind putting people first for these successful leaders? </li>
      <li>The importance given by these leaders to creating value within the organization, to create a positive corporate culture which in turn impacts the growth of their companies too. </li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://indiaincgroup.com/the-indian-ceo-from-values-to-value-creation/" />

      <ExternalResource url="https://knowledge.wharton.upenn.edu/article/microsofts-ceo-on-how-empathy-sparks-innovation/" />
    </div>
  </div>
}

export default unit;