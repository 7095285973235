import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>What is success to you? A job at the best IT Company in the world? A steady income and assured growth at a company? Munaf Patel could have stayed in his comfortable job at google, something most people dream of but he wanted to do something different.  The stories below showcase Munaf’s journey of building his company and how he moved from a successful corporate job to a successful entrepreneurship.</p>
    <p>While going through his story, pay special attention to:</p>
   
    <ul>
      <li>The true driving force for him to begin the Bohri kitchen and the way the meaning of success changed for him while he tried to set up the Bohri kitchen.</li>
      <li>The Bohri Kitchen not only helped him connect better to his own culture but also introduced many more people to his culture.</li>
      <li>The resilience he showed when he went bankrupt while starting the business, the fact that he learned from his mistakes and adapted to the circumstances instead of giving up.</li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://www.bbc.com/news/business-41467962' />
      <ExternalResource url='https://www.thebetterindia.com/231449/google-samosa-entrepreneur-bohri-food-dawoodi-bohra-nalli-nihari-chicken-biryani-traditional-recipes-mumbai-ang136/' />
      <ExternalResource url='https://www.youtube.com/watch?v=VHXSb2H03WE' />
    </div>
  </div>
}

export default unit;