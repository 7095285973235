import React, { useEffect } from 'react';
import units from '../../explorations';
import Grid from '@material-ui/core/Grid';
import Header from '../Header';

let Links = (props) => {
  let { unit, updateReadingTracker, resourceId, uid } = props;
  useEffect(() => {
    updateReadingTracker({ uid, resourceId })
  }, [])
  return <div>
    <Header />
    <Grid container spacing={3} className='external-links' style={{ padding: 32, marginTop: 32 }}>
      <Grid item xs={12} sm={4} style={{ font: 'bold 22px / 1.6 Merriweather' }}>
        <div>{unit.title}</div>
        <div><img src={unit.icon} /></div>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Grid container>
          <Grid item xs={12} sm={8} style={{ borderTop: '1px solid #BCBCBC', paddingTop: 32 }}>{units[resourceId]()}</Grid>
        </Grid>
      </Grid>
    </Grid>
  </div>
}

export default Links