import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>In the videos below, you will hear from two stalwarts of the Indian corporate world. Suresh Narayanan, Chairman and MD, Nestle India and R Gopalakrishnan, former MD of Brooke Bond Lipton, former Vice Chairman of Hindustan Lever, and former Director of Tata Sons.</p>
    <p>Both these leaders have been instrumental in building strong, long lasting institutions. The reason they were able to do so was because of the key lessons they learnt through their careers. They did not merely do their job, they stayed committed to doing their job with a human touch and compassion. They also learnt many critical lessons while they were on the job which in turn ensured they could become good leaders. </p>
    <p>Keep in mind the following while going through the videos below:</p>
    <ul>
      <li>Suresh Narayanan’s stories talk about the way his Chairman placed his faith in him at a tough time. In the second story one sees how he himself, displayed empathy while laying off workers in his company. These were people who were being let go off by his company, but even when he was tasked with firing them, he did so with compassion, and it is this compassion that ensured the whole process happened smoothly.</li>
      <li>R Gopalakrishnan talks about an incident that was instrumental in making him more humble and actually listen to his subordinates. What does this say about his ability to learn from his own mistakes, and apply this learning to his own leadership style?</li>
    </ul>
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://www.youtube.com/watch?v=tZXNdg9_sXI' />
      <ExternalResource url='https://www.youtube.com/watch?v=b2fxqnYZopg' />
      <ExternalResource url='https://www.youtube.com/watch?v=r0IHiZMsa3k&feature=youtu.be' />
    </div>
  </div>
}

export default unit;