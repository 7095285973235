import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>Have you ever looked at the achievement of others and felt jealous or bad about your own life? Have you been mindful of why you think this way?</p>
    <p>It is easy to get swept up by our emotions, to experience self-pity and feel powerless in situations. But, according to psychotherapist, Amy Morin, indulging in self-pity ensures you lose power over yourself and the situation you are in.</p>
    <p>While watching the video think of the following: </p>
    <ul>
      <li>The three unhealthy beliefs that people hold according to Morin. 
        <ul>
          <li>Unhealthy Beliefs About Yourself - Thinking that you are incapable.</li>
          <li>Unhealthy Beliefs About Others - Thinking that people are against you.</li>
          <li>Unhealthy Beliefs About the World – Thinking that the world is unfair.</li>
        </ul>
      </li>
      <li>Countering unhealthy beliefs with healthy ones:
        <ul>
          <li>Stop yourself from running away from uncomfortable emotions.</li>
          <li>Do not compare yourself to other people or blame others for holding you back, </li>
          <li>Accepting the fact that your world is what you make of it. </li>
        </ul>
      </li>
      <li>The example of the diabetic patient who believed he was doomed to being sick, and how taking control of his own habits and developing his own mental strengths helped him reverse his illness.</li>
    </ul>
    
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://www.youtube.com/watch?v=TFbv757kup4" />
      <ExternalResource url="https://www.inc.com/amy-morin/3-types-of-unhealthy-beliefs-that-will-drain-your-mental-strength-make-you-less-effective.html" />
    </div>
  </div>
}

export default unit;