import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>You may have heard the famous words by Mahatma Gandhi, “You must be the change, you want to see in the world.” Changemakers are an important part of society, with their work and their will; they can bring about massive shifts in their community and even in the world. So how do you become one?</p>
    <p>The first article below will give you an insight into how one becomes a changemaker, what are some of the key steps needed to bring about significant change around you. In the second article you will learn about many Indians who transformed the lives of many through their work. </p>
    <p>While going through the articles, think of some of the principles spoken about in the first article – such as having a roadmap, being prepared for setbacks, showing commitment to your work. </p>
    <p>Think of the stories of the changemakers in the second article, can you see some of the methods spoken about in the first article play out in the second one?</p>
    <p>For eg. Ajeet Singh, Founder of Guria was only 18 years old when he committed to put an end to sexual exploitation of girls despite opposition from his own family. </p>
    <p>Anshu Gupta built a roadmap for Goonj that went beyond providing clothes to the needy but started thinking of more ways in which he could bring about long lasting change for the people. </p>
    
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://medium.com/@dekecopenhaver/the-art-of-being-a-changemaker-how-to-do-it-and-why-it-matters-8cefacfcf991" />
      <ExternalResource url="https://www.youthkiawaaz.com/2019/08/inspiring-stories-of-indias-changemakers/" />
      {/* <ExternalResource url="https://kalingatv.com/state/pm-modi-hails-odisha-teacher-omprakash-mishra" title='PM Modi hails Odisha teacher Omprakash Mishra | KalingaTV' icon='https://cdn.kalingatv.com/wp-content/uploads/2018/09/Modi-with-Omprakash-Mishra.jpg' /> */}
    </div>
  </div>
}

export default unit;