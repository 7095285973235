import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>The idea of Uber was first conceived when former CEO Travis Kalanick met Garrett Camp and they discussed the possibility of a reliable and quickly accessible black car service which led to the original tagline of the company of “Everyone’s Private driver”, they launched the app in 2010 in the city of San Francisco. Today, Uber makes money by charging its clients for cab rides, offering food deliveries and advertising. The money paid by the client is divided between the cab driver and the company with the company taking 20% of the profits. </p>
    <p>Uber’s business model creates a win-win situation for all parties involved, right from the customer to the driver and the app service provider.  Despite facing legal challenges across their markets, Uber has successfully transformed the public transportation landscape across the world. Similar to Uber, companies like Airbnb and Etsy have also transformed their respective business landscapes with breakthrough, profitable ideas. </p>
    {/* <div className='f15_b'>While watching the video, look out for:</div> */}
    <p>While reading the article below, keep in mind:</p>
    <ul>
      <li>Uber’s unique value proposition to the market that ensures customers keep choosing them over other services. </li>
      <li>How did AirBnB and Uber transform their respective industries, what value did they add to the market? </li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://hbswk.hbs.edu/item/how-uber-airbnb-and-etsy-attracted-their-first-1-000-customers' />
    </div>
  </div>
}

export default unit;