import React from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  Input,
  InputLabel,
  InputAdornment,
  FormControl,
  Button,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "@material-ui/icons";

// import { sideNavData } from "./tileData";
// import ForgotPassword from "../../containers/ForgotPassword";
import "./Registration.css";

const styles = {};

class Registration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      showPassword: false,
      accesscode: "",
      fullname: "",
    };

    this.handleChange = (prop) => (event) => {
      this.setState({ [prop]: event.target.value });
    };

    this.handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    this.handleClickShowPassword = () => {
      this.setState({ showPassword: !this.state.showPassword });
    };
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={"auth-form-cntr"}>
        <form>
          <div className="registerform-cntr">
            <FormControl fullWidth className={"input-cntr"}>
              <InputLabel htmlFor="adornment-password">Email</InputLabel>
              <Input
                id="user-email"
                label="Email"
                type="text"
                autoComplete="on"
                value={this.state.email}
                onChange={this.handleChange("email")}
              />
            </FormControl>
            <FormControl fullWidth className={"input-cntr"}>
              <InputLabel htmlFor="adornment-password">Password</InputLabel>
              <Input
                id="user-password"
                label="Password"
                autoComplete="off"
                type={this.state.showPassword ? "text" : "password"}
                value={this.state.password}
                onChange={this.handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {this.state.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            {/* <FormControl
            fullWidth
            className={"input-cntr"}
          >
            <InputLabel htmlFor="adornment-password">Access Code (optional)</InputLabel>
            <Input
              id="accesscode"
              label="Access Code"
              type="text"
              autoComplete="on"
              value={this.state.accesscode}
              onChange={this.handleChange("accesscode")}
            />
          </FormControl> */}

            <FormControl fullWidth className={"input-cntr"}>
              <InputLabel htmlFor="adornment-password">Full name</InputLabel>
              <Input
                id="fullname"
                label="Full Name"
                type="text"
                value={this.state.fullname}
                onChange={this.handleChange("fullname")}
              />
            </FormControl>

            <div className="errorMsg">{this.props.authMsg}</div>
            <FormControl
              className={"input-cntr"}
              style={{ display: "flex", alignItems: "flex-end" }}
            >
              <Link
                className="forgot-pw"
                // component={Link}
                to="/auth/reset-password"
                style={{
                  margin: "10px 0",
                  textDecoration: "none",
                  color: "#b9b9b9",
                  fontSize: ".815rem",
                }}
              >
                Forgot Password
              </Link>
            </FormControl>
            <div className="rl-btns">
              <FormControl
                fullWidth
                className={"input-cntr"}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  className="RegistrationBtn"
                  style={{
                    background: "#e6111b 0% 0% no-repeat padding-box",
                    color: "white",
                    width: "200px",
                    height: "45px",
                    marginTop: "35px",
                  }}
                  onClick={() => {
                    let { email, password, accesscode, fullname } = this.state;
                    this.props.registerAttempt({
                      email,
                      password,
                      accesscode,
                      fullname,
                      successCallBack: () => {
                        // console.debug("success");
                      },
                      failureCallback: () => {
                        // console.log("failed");
                      },
                    });
                  }}
                >
                  Register
                </Button>
              </FormControl>
              <FormControl
                fullWidth
                className="login-link-cntr"
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "10px 0",
                  color: "#e6111b",
                }}
              >
                <Link
                  className="login-link"
                  to={"/auth/login"}
                  style={{ color: "#e6111b", textDecoration: "none" }}
                >
                  Login
                </Link>
              </FormControl>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

Registration.propTypes = {
  classes: PropTypes.object.isRequired,
  registerAttempt: PropTypes.func,
  authMsg: PropTypes.string,
};

export default withStyles(styles)(Registration);
