import React from 'react';
import ExternalResource from '../../../components/ExternalLink';
let unit = (props) => {
    return <div>
        <p>For any sports training academy, it is important that the sports persons trained by them perform well and win in tournaments. The training routine is tough and every person in the team is expected to follow it. However, when ‘Winning’ becomes a sole focus and the concerns of the people being trained are overlooked it has grave human consequences. The video shows journey of Mary Cain and the cost she had to pay due to ‘win- at- all- cost ‘approach taken by her coaches.</p>
        <p>While going through the video below, think of the following:</p>
        <ul>
            <li>Why was it important for people in an authoritative position to value humans involved as much as the success that needs to be achieved?</li>
            <li>The impact on the people when a rigid ‘one size fits all’ approach is taken and not modified according to the unique needs on an individual involved?</li>
        </ul>

        <div className='highlighted-patch'>
            <div className='f15_b'>Links</div>
            <ExternalResource url='https://www.nytimes.com/video/opinion/100000006788354/nike-running-mary-cain.html?action=click&gtype=vhs&version=vhs-heading&module=vhs&region=title-area&cview=true&t=1' />
        </div>
    </div>
}

export default unit;