import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>The links below tell the story of Dorothy Vaughan, and her fellow human computers. These were African American women, who faced discrimination at the workplace. They were initially asked to work in a segregated section, away from their white colleagues.</p>
    <p>Even though the administration did not treat them well, they human computers did not let the organization’s limits on their movement hamper their ability to make a difference, they had enough self-belief and inner strength to make significant contributions to NASA. They focused on the areas they had the freedom to act and so great was their work that NASA honours them even till the present day.</p>
    <p>While reading the two articles below, think of the following:</p>
    <ul>
      <li>Dorothy and her co-workers were treated badly at NASA, but through her attitude of taking charge and not viewing her own self as any lesser than those around her, Dorothy was able to make massive changes. </li>
      <li>How did Dorothy Vaughan stand up for her team and their welfare?</li>
      <li>What did her “trailblazing career” and never give up attitude achieve for women who followed in her footsteps?</li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://interestingengineering.com/dorothy-vaughan-nasas-human-computer-and-american-hero' />
      <ExternalResource url='https://www.nasa.gov/content/dorothy-vaughan-biography' />
    </div>
  </div>
}

export default unit;