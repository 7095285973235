/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import AccessCode from "./p_AccessCode";
import { useMediaQuery } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuOpen from '@material-ui/icons/MenuRounded';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import FirstTimeUser from './firstTimeUserMsg';

import { tabIndexChange } from './redux_actions';
import './resources.css'

const Resources = (props) => {
  // const history = useHistory();
  const isDesktop = useMediaQuery("(min-width:980px)");
  let currentModule = useSelector((state) => state.app.currentModule);
  let myModules = useSelector((state) => state.app.myModules);
  let units = useSelector((state) => state.app.units);
  let userId = useSelector((state) => state.app.userId);
  let { user_idtoken } = useSelector((state) => state.auth);
  let tabIndex = useSelector((state) => state.app.tabIndex);
  let dispatch = useDispatch();



  const [mobileNavOpen, setMobileNavOpen] = React.useState(false);
  const [isAccessCodeOpen, setAccessCodeOpen] = React.useState(false);
  var w = window.innerWidth;
  var h = window.innerHeight;
  var deviceRatio = w / h;
  let mdCntrWd = w - 2 * 24 - 200 - 96;
  function mobileNav(items, value, handleChange) {
    if (items)
      return <div>
        <div style={{
          height: 64, backgroundColor: '#F7F7F7', display: 'flex',
          justifyContent: 'center', alignItems: 'center', padding: '0px 24px', margin: '0px -24px'
        }} onClick={() => { setMobileNavOpen(true) }}>
          <div style={{ flexGrow: 1 }}>
            <div style={{ fontSize: 10, marginBottom: 6, color: '#8C8C8C' }}>Select a module</div>
            <div style={{ fontSize: 15, fontWeight: 700, textTransform: 'capitalize' }}>{items.type === 'Project' ? 'Project:' : 'Module:'}</div>
          </div>
          <MenuOpen fontSize='large' />
        </div>
        <Dialog onClose={() => { setMobileNavOpen(false) }} open={mobileNavOpen}>
          <DialogTitle>Select a module</DialogTitle>
          <List>
            {Object.keys(items || {}).map((el, i) => {
              if (Object.keys(items[el]).length) {
                return <ListItem disabled={items[el].units ? false : true} selected={i === value} button onClick={() => { dispatch(tabIndexChange(i)); setMobileNavOpen(false) }} key={el}>
                  <ListItemText primary={items[el].title} style={{ fontSize: 15, fontWeight: 700, }} />
                </ListItem>
              } else {
                return null;
              }
            })}
          </List>
        </Dialog>
      </div>
  }

  const handleTabChange = (event, newValue) => {
    dispatch(tabIndexChange(newValue))
  }

  function desktopNav(items, value, handleChange) {
    if (items)
      return <div style={{ width: 200 }}>
        <div style={{ fontSize: 13, padding: '16px 0px 8px', fontWeight: 700, textTransform: 'capitalize' }}>Jump To</div>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={tabIndex || 0}
          onChange={handleChange}
          aria-label="jump-to"
        >
          {Object.keys(items || {}).map((el, i) => {
            let item = items[el];
            // console.log(item.units);
            let a = <div style={{ textAlign: 'left', textTransform: 'initial' }}>
              <div style={{ fontSize: 9, fontWeight: 500, paddingBottom: 4 }}>{item.type === 'Project' ? 'Project:' : 'Module:'}</div>
              <div style={{ fontSize: 12, fontWeight: 700 }}>{item.title}</div>
            </div>
            if (Object.keys(item).length) {
              return <Tab
                classes={{ wrapper: 'tab-wrapper' }} disabled={item.units ? false : true}
                key={el} label={a}
                style={{ padding: '16px 16px 16px 0px', borderTop: '1px solid #999', width: 200 }} />
            } else {
              return null;
            }
          })}
        </Tabs>
      </div>
  }

  function moduleCntr(items, value) {
    return <div style={{ flexGrow: 1 }}>
      {Object.keys(items || {}).map((el, i) => {
        let item = items[el];
        return <div key={el} role="tabpanel" hidden={value !== i} id={`vertical-tabpanel-${i}`} value={value} index={i} style={{ padding: `0px 0px 0px ${isDesktop ? 96 : 0}px` }}>
          <div style={{ fontSize: 13, padding: '16px 0px 8px', fontWeight: 700, textTransform: 'capitalize' }}>Module:</div>
          <div style={{ padding: '0px 0px 16px', fontSize: 20, fontWeight: 700, lineHeight: 1.4 }}>{item.title}</div>
          <div style={{ display: 'flex', margin: '0px -16px', flexWrap: 'wrap' }}>
            {Object.keys(item.units || {}).map(el => {
              if (!item.units[el]) { return null }
              let unit = units ? units[el] : {};
              let to = unit && unit.illumineResource ? `${unit.illumineResource}&uid=${userId}&token=${user_idtoken}` : `/app/resource/${el}`;
              let child = <div>
                <div style={{ backgroundColor: '#ECECEC', display: 'flex' }}>
                  <img src={unit && unit.icon ? unit.icon : null} width='100%' height='auto' />
                </div>
                <div style={{ padding: 16 }}>
                  <div style={{ fontSize: 14, color: '#999', paddingBottom: 8 }}>{unit && unit.subTitle ? unit.subTitle : ''}</div>
                  <div style={{ color: '#000', fontWeight: 700 }}>{unit && unit.title ? unit.title : ''}</div>
                </div>
              </div>
              return <UnitLink key={el} flag={unit && unit.illumineResource ? unit.illumineResource : false} child={child} to={to} />
            })}
          </div>
        </div>
      })}
    </div>
  }


  return <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', flexGrow: '1' }}>
    <Header />
    <div style={{ display: 'flex', flexDirection: isDesktop ? 'row' : 'column', padding: 24 }}>
      {isDesktop
        ? desktopNav(myModules, tabIndex, (ev, val) => { dispatch(tabIndexChange(val)) })
        : mobileNav(myModules, tabIndex, (ev, val) => { dispatch(tabIndexChange(val)) })}
      {myModules ? <div>
        {moduleCntr(myModules, tabIndex)}
        <div style={{ float: 'right' }} onClick={() => { setAccessCodeOpen(true) }}>
          <AddCircleIcon style={{ fontSize: 40, fill: '#7CBA00' }} />
        </div>
      </div> : <FirstTimeUser user={{ uid: userId }} />}
    </div>
    <Footer />
    <Dialog open={isAccessCodeOpen} onClose={() => { setAccessCodeOpen(false) }}>
      <AccessCode user={{ uid: userId }} onSuccess={() => { setAccessCodeOpen(false) }} onFailure={() => { setAccessCodeOpen(false) }} />
    </Dialog>
  </div>;
};

export default Resources;


function UnitLink({ flag, child, to }) {
  let s = { width: 272, border: '1px solid #DCDCDC', display: 'block', textDecoration: 'none', margin: 16 }
  if (flag) {
    return <a href={to} style={s}>{child}</a>
  } else {
    return <Link to={to} style={s}>{child}</Link>
  }
}