import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>The following article showcases 21 questions that everyone needs to ask themselves to understand the kind of success they want.</p>
    <p>While going through the article notice the following about yourself:</p>

    <ul>
      <li>If these questions are easy for you to answer</li>
      <li>How many times did words normally associated with success (eg. Promotions, higher salary, nice vacation) showed up while answering these questions</li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://thriveglobal.com/stories/these-21-questions-are-guaranteed-to-make-you-more-successful" />
      {/* <ExternalResource url="https://kalingatv.com/state/pm-modi-hails-odisha-teacher-omprakash-mishra" title='PM Modi hails Odisha teacher Omprakash Mishra | KalingaTV' icon='https://cdn.kalingatv.com/wp-content/uploads/2018/09/Modi-with-Omprakash-Mishra.jpg' /> */}
    </div>
  </div>
}

export default unit;