import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
    return <div>
        <p>Uber has always been known as a disruptor, however, a couple of years ago the company was in the news for all the wrong reasons. They had profited from a taxi strike in New York City instead of supporting taxi drivers and an employee had spoken out about the abuse that went on at the company and taxi drivers complained about the fact that they did not earn a living wage with the company. Uber had been a great idea, but it was now losing the trust of its customers and drivers. In the article below the writers decode how Uber regained trust in the market.</p>
        <p>While going through the article below, think of the following:</p>
        <ul>
            <li>Why was it important for leaders within the company to showcase trust behaviour so that the company thrived?</li>
            <li>The impact on the company when its people were working in a low trust environment.</li>
            <li>Why did the company&rsquo;s attitude to &ldquo;grow at all costs,&rdquo; hurt its growth instead of helping it?</li>
        </ul>

        <div className='highlighted-patch'>
            <div className='f15_b'>Links</div>
            <ExternalResource url='https://hbr.org/2020/05/begin-with-trust' />
        </div>
    </div>
}

export default unit;