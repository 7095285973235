import React from 'react';
import ExternalResource from '../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>While reading the article below, you will learn new methods of solution thinking and also get a deeper understanding of ways to generate innovative solutions to challenges.</p>
    <p>One of the most important skills needed in the new world of work, involves combining creativity, imagination and critical thinking to come up with new solutions to old problems. Organizations, therefore, value the ability to think outside of the box and change the status quo among their employees. </p>
    {/* <div className='f15_b'>While watching the video, look out for:</div> */}
    <p>In the articles below you will find more insights into the current needs of the workplace along with tips on how to reframe problems to find innovative solutions. While reading the articles below keep in mind the following:</p>
    <ul>
      <li>The importance of the three strategies presented: rethinking the problem, brainstorming bad ideas and unpacking your assumptions when you are faced with a challenge.</li>
      <li>Note how brainstorming bad ideas and unpacking your assumptions ensures that you come up with alternative approaches to look at the challenge itself and define your goal as well as develop creative solutions to meet the goal.</li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://www.fastcompany.com/3050265/three-ways-to-reframe-a-problem-to-find-innovative-solution' />
    </div>
  </div>
}

export default unit;