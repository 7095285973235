import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>We often think of our jobs as mechanical, as a set of activities that need to be completed. In the stories below, we showcase how even the most mundane jobs have the opportunity for contribution. The stories below showcase how it does not take much to create an environment of contribution. All it needs is a strong desire to contribute and make a difference in people's lives.</p>
    <p>While going through the stories below, please keep in the mind the following:</p>

    <ul>
      <li>Both Udaybhai and Annadurai have turned their rickshaws into vehicles of contribution by thinking about their customers as people whose lives they can positively impact, even if for a few moments in a day.</li>
      <li>The tone with which they talk about their jobs, their approach to service, and the immense pride they take in being able to do their jobs.</li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://www.youtube.com/watch?v=oR-6FfiUraU&feature=youtu.be" />
      <ExternalResource url="https://www.thebetterindia.com/4813/tbi-heroes-ahmedabad-no-rickshawalo-personifying-love-all-serve-all/" />
      {/* <ExternalResource url="https://kalingatv.com/state/pm-modi-hails-odisha-teacher-omprakash-mishra" title='PM Modi hails Odisha teacher Omprakash Mishra | KalingaTV' icon='https://cdn.kalingatv.com/wp-content/uploads/2018/09/Modi-with-Omprakash-Mishra.jpg' /> */}
    </div>
  </div>
}

export default unit;