import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>With the advent of automation and AI, the factory mindset - doing procedural tasks, that require almost no thinking, and boring desk jobs will change. Work itself will be different. Innovation leader, David Lee thinks that in ten years from now the workplace will value people who are creators and innovators. Who contributes to the growth of their company and are committed to testing new ideas.</p>
    <p>The first article below gives you an example of the job loss that happens due to automation.</p>
    <p>The video will help you learn more about how this new workplace will look and how the idea of work will be completely transformed.</p>
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://trak.in/tags/business/2021/02/01/tech-mahindra-will-fire-these-5000-employees-due-to-rise-in-automation-humans-no-longer-needed/' />
      <ExternalResource url='https://www.ted.com/talks/david_lee_why_jobs_of_the_future_won_t_feel_like_work?language=en' />
    </div>
  </div>
}

export default unit;