import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
    return <div>
        <p>Mama Earth, a home grown skin care brand has managed to become a Rs 100 crore company within four years of its launch. Behind their success is a key motivation that drives the company &ndash; ensuring the highest quality products for their customers. The secret to their success is that they know what their customers need and will want in the future. As a business they are not focused on pushing their products onto customers, but actually providing them with a better alternative that will enhance their life. This is what has made the Mama Earth brand popular.</p>
        <p>While reading about their growth story, keep in mind the following:</p>
        <ul>
            <li>The fact that they launched their brand because they wanted to provide something safe to parents just like them.</li>
            <li>To them functional factors are as important as human factors as they are even committed to recycling the plastic they use to ensure the planet is safe.</li>
        </ul>
        <div className='highlighted-patch'>
            <div className='f15_b'>Links</div>
            <ExternalResource url='https://yourstory.com/smbstory/mothers-day-mamaearth-varun-ghazal-alagh-entrepreneur-gurugram' />
        </div>
    </div>
}

export default unit;