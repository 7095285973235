import React from 'react';
import ExternalResource from '../../../components/ExternalLink';
let unit = (props) => {
    return <div>
        <p>The idea of Uber was first conceived when former CEO Travis Kalanick met Garrett Camp and they discussed the possibility of a reliable and quickly accessible black car service which led to the original tagline of the company of &ldquo;Everyone&rsquo;s Private driver&rdquo;, they launched the app in 2010 in the city of San Francisco. Today, Uber makes money by charging its clients for cab rides, offering food deliveries and advertising. The money paid by the client is divided between the cab driver and the company with the company taking 20% of the profits.&nbsp;</p>
        <p>Despite facing legal challenges across their markets, Uber has successfully transformed the public transportation landscape across the world. Similar to Uber, companies like Airbnb and Etsy have also transformed their respective business landscapes with breakthrough, profitable ideas.</p>
        <p>While reading the article below, keep in mind:</p>
        <ul>
            <li>Their app created a new experience for users and improved the way they did one of the most basic tasks, ensuring that booking rides became cheaper and easier.</li>
            <li>They transformed the way taxi drivers connected with customers, thereby transforming the way taxi drivers offered their services to customers, eg. Introduction of ratings and feedback.</li>
        </ul>
        <p>Companies like Uber and Airbnb have not just build applications, but disrupted entire sectors through their work, this has not only brought financial value to these companies but also added value to society at large.</p>
        <div className='highlighted-patch'>
            <div className='f15_b'>Links</div>
            <ExternalResource url='https://hbswk.hbs.edu/item/how-uber-airbnb-and-etsy-attracted-their-first-1-000-customers' />
        </div>
    </div>
}

export default unit;