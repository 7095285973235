import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>Today, JK Rowling, author of the Harry Potter series is an acclaimed writer who is well known across the world. Her books have been on bestseller lists and she has sold more than 500 million copies of her book. But, before she became a bestselling author, Rowling like many people had faced her fair share of failures (at a point in time she was on the brink of being homeless).</p>
    <p>However, to her failure was not a set -back, in fact it helped propel her towards success.</p>
    <p>While reading the article below think of the following:</p>
    <ul>
      <li>The Harry Potter manuscript was rejected by 12 publishers, yet JK Rowling  did not give up hope and eventually got it published, what did she say to herself to keep on moving forward?</li>
      <li>Despite being in a tough situation, she was fearless as she thought she had nothing to worry about, nothing holding her back.</li>
    </ul>
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://medium.com/the-mission/how-to-transform-your-failures-into-insane-success-according-to-j-k-rowling-54ebf876e2d" />
      <ExternalResource url="https://imperfectionistblog.com/2017/11/failogue-j-k-rowling-was-the-biggest-failure-she-knew/" />
    </div>
  </div>
}

export default unit;