import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>The stories below showcase people who were inspired to bring about change in their communities. The interesting part about their stories is that they were inspired to bring about change because of their approach to personal set- backs.</p>
    <p>Avilash Mahananda lost his mother to cancer. When he realized that it was due to contaminated, polluted food that may have caused his mother’s cancer he decided to focus on finding a solution to the issue. This is what lead to the birth of his start up that supplies toxin free vegetables to the market.</p>
    <p>Similarly, Sumona Karjee Mishra’s pregnancy complications lead her to build a product that would ensure others did not suffer the way she had.</p>
    <p>While reading the articles below keep in mind the following:</p>
    <ul>
      <li>If Avilash and Mahananda had adopted a caged response, they could have either blamed their circumstances or thought it was someone else’s job to come up with a solution. Instead, they used this moment to build themselves.</li>
      <li>They were focused on creating a better future not only for themselves but others around them.</li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://yourstory.com/2019/08/bhubaneswar-biotech-startup-prantae-solutions-pregnant-women" />
      <ExternalResource url="https://mycitylinks.in/this-iim-graduate-is-taking-toxin-free-veggies-to-common-masses/" />
    </div>
  </div>
}

export default unit;