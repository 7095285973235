import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>In the article below you will get an insight into the mind of runner Heather Dorniden Kampf. While running in a race, Kampf tripped and fell just as she was getting close to the finish line. In that moment, Kampf could have given up, thinking that she had lost the race, but this was her own personal “I Can” moment wherein she got up and decided to finish the race. In the interview below Dorniden Kampf talks about what went through her mind as she fell and then got up to run again.</p>
    <p>While going through her interview and the video of the run below think of the following:</p>
    <ul>
      <li>The fact that in the moment Dorniden Kampf did not give up but continued to run.</li>
      <li>That she believed she could win the race despite the fall, and it was this “I Can” attitude that finally got her across the finish line.</li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://btn.com/2015/06/03/a-race-to-remember-i-had-no-idea-i-fell-like-that-in-inspirational-2008-run/' />
      <ExternalResource url='http://citiusmag.com/heather-kampfs-midrace-fall-race-video/' />
    </div>
  </div>
}

export default unit;