import React from 'react';
import ExternalResource from '../../../components/ExternalLink';
let unit = (props) => {
    return <div>
        <p>One of the most important skills needed in the new world of work, involves combining creativity, imagination and critical thinking to come up with new solutions to old problems. Organizations, therefore, value the ability to think outside of the box and change the status quo among their employees. While reading the article below, you will learn how to look at a problem in an entirely new light to ensure you find the solution.</p>
        <ul>
            <li>The importance of the three strategies presented: rethinking the problem, brainstorming bad ideas and unpacking your assumptions when you are faced with a challenge.</li>
            <li>The focus on the end goal that is brought on when you reframe the question.</li>
        </ul>
        <div className='highlighted-patch'>
            <div className='f15_b'>Links</div>
            <ExternalResource url='https://www.fastcompany.com/3050265/three-ways-to-reframe-a-problem-to-find-innovative-solution' />
        </div>
    </div>
}

export default unit;