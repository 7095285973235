import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
    return <div>
        <p>An important facet of gaining someone&rsquo;s trust is to deliver on your promises. Very often we see ourselves making promises we cannot keep.</p>
        <p>Imagine this; you are in a group project and you promise to do a large part of the work but before the day of submission you tell your partners you have not been able to do the work you had taken on. Now all your group partners must help you finish the work. Your group&rsquo;s trust in you has been eroded, even though you were enthusiastic and well meaning when you offered to do the work, the fact that you were not able to deliver on this promise is what will make you look untrustworthy.</p>
        <p>In the article below you will learn more about why you should never &ldquo;over promise&rdquo; and &ldquo;under-deliver,&rdquo; and what happens when you do so. </p>

        <div className='highlighted-patch'>
            <div className='f15_b'>Links</div>
            <ExternalResource url='https://www.entrepreneur.com/article/345124' />
        </div>
    </div>
}

export default unit;