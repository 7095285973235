import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>What would you do if you were faced with multiple challenges time and time again? When Jangyaseni Jena was told she lost her job, she reinvented herself as a business person, when her business was sabotaged, she decided to start another initiative. Jena was a social worker, but eventually she made more of an impact as an entrepreneur. Through her life whenever she was faced with a challenge, Jena adapted to the challenge instead of getting fazed by it.</p>
    <p>Listen to Jim’s story below to understand:</p>
    
    <ul>
      <li>The different roles Jena had to take on every time she was faced with a new challenge?</li>
      <li>Would she have become an entrepreneur if she had a fixed identity and only thought of herself as a social worker?</li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://yourstory.com/smbstory/this-odisha-woman-is-lightning-up-lives-of-rural-women-with-her-agarbattis" />
    </div>
  </div>
}

export default unit;