import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
    return <div>
        <p>Covid -19 has exposed and laid bare the fault lines that were already existing in the various systems on which our country runs such as our healthcare systems, our economy, our government policies, etc. We have seen cases of apathy towards patients in hospitals, lack of planning and co-ordination, lack of consideration of the consequences of policies or decisions on people.</p>
        <p>These instances highlight the sheer and critical need for Imaginative Sympathy going forward and<strong> here are ordinary people who already recognize this need: </strong></p>
        <p><strong>While reading the article below, think of the following:</strong></p>
        <ul>
            <li>How a regular district collector built and put into place a solution for the COVID 19 spike in his district by visualizing the potential problems that his people might face and imagining the consequences of not taking quick action?</li>
            <li>How watching a news report made a simple auto driver visualize the conditions of those who were suffering from lack of ambulances and prompted him to help them out? How many times are people inspired to do this?</li>
        </ul>
        <p>How a young engineer built an innovation that provides respite to healthcare workers in hospitals by imagining the little visible/invisible needs of the patient with respect to healthcare worker such as nurses and ward boys?<strong><br /><br /></strong></p>
        <div className='highlighted-patch'>
            <div className='f15_b'>Links</div>
            <ExternalResource url='https://www.thebetterindia.com/253438/ias-hero-nandurbar-district-collector-maharashtra-second-wave-coronavirus-vaccination-oxygen-plant-beds-ventilator-pandemic-india-inspiring-gop94/' />
            <ExternalResource url='https://www.hindustantimes.com/videos/coronavirus-crisis/bhopal-auto-driver-turns-his-3-wheeler-into-free-ambulance-for-covid-patients-101619777991213.html?utm_source=ht_site_copyURL&amp;utm_medium=social&amp;utm_campaign=ht_site' />
            <ExternalResource url='https://www.tribuneindia.com/news/schools/maharashtra-boy-makes-worlds-first-internet-controlled-robot-95839' />
        </div>
    </div>
}

export default unit;