import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>The articles below will give you examples of how people with caged thinking respond to different situations and how it impacts one’s performance at work. Important terms used in the articles below are that of the arrogant victim mindset and doubtful victim mindset.</p>
    <p>While reading the articles below keep the following in mind:</p>
    
    <ul>
      <li>When you start dwelling on the negative aspects of a situation, feel sorry for yourself or blame others for your shortcomings you display the victim mindset. How does this mindset hamper your performance at work?</li>
      <li>A person with the arrogant victim may think he is never wrong and looks down upon others around him, while one with the doubtful victim mindset may have no faith in his own capabilities.</li>
    </ul>
    <p> Learn more about the kind of responses that might occur at the workplace and how one can be pulled out of this mindset.</p>
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://www.forbes.com/sites/forbescoachescouncil/2017/01/11/how-to-manage-the-arrogant-victim-mindset/" />
      <ExternalResource url="https://www.forbes.com/sites/forbescoachescouncil/2016/12/28/how-to-manage-the-doubtful-victim-mindset/" />
      {/* <ExternalResource url="https://kalingatv.com/state/pm-modi-hails-odisha-teacher-omprakash-mishra" title='PM Modi hails Odisha teacher Omprakash Mishra | KalingaTV' icon='https://cdn.kalingatv.com/wp-content/uploads/2018/09/Modi-with-Omprakash-Mishra.jpg' /> */}
    </div>
  </div>
}

export default unit;