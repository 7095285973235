import React from 'react';
import ExternalResource from '../../components/ExternalLink';
let unit = (props) => {
  return <div>
    <p>In a world of work that will be driven by artificial intelligence (AI) and automation, what will be expected of human beings? In the articles and videos below you will learn more about the skills students need to have to flourish at the workplace – right from empathy, emotional intelligence and critical thinking to being open to learning and adapting to a changing world of  work constantly.</p>
    
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url="https://www.forbes.com/sites/bernardmarr/2019/04/29/the-10-vital-skills-you-will-need-for-the-future-of-work/?sh=66eafc043f5b" />
      <ExternalResource url="https://www.youtube.com/watch?v=Azm6Tr4LUYg&feature=youtu.be" />
      <ExternalResource url="https://www.youtube.com/watch?v=UV46n44jnoA&t=6s" />
    </div>
  </div>
}

export default unit;